import BaseForm from '../BaseForm'

import React from 'react'
import { Button, Form, Modal, Nav, Row } from 'react-bootstrap'

import FormControlsRender from '../../../Utils/FormControlsRender'
import { Text, TextArea, Numeric, DatePicker } from '../../../Utils/FormControlsPresets'
import SearchInputObj from '../../../Components/General/SearchInputs/searchInputObj'
import SearchInputAddr from '../../../Components/General/SearchInputs/searchInputAddr'
import Tab from '../../../Components/General/Tab'
import Guid from '../../../Components/Guid'
import Map from '../../../Components/Map/Map'
import ZoneForm from '../zones/ZoneForm'
import Zone from '../../../Components/Zone'
import LeakageForm from '../leakages/LeakageForm'
import LeakageSensor from '../../../Components/Devices/LeakageSensor'
import LsForm from '../accounts/LsForm'
import LS from '../../../Components/LS'
import ConfirmModal from '../../../Components/General/ConfirmModal'

export default class ValveNbiotForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      navKey: '1',
      requestProcess: false,
      card: {},
      formControls: {
        SERIAL_NUMBER: Text('Серийный номер'),
        IMEI: Text('IMEI'),
        ICCID: Text('ICCID'),
        EXTERNAL_ID: Text('Внешний ID'),
        HW_VER: Text('Версия ПО'),
        SW_VER: Text('Ревизия'),
        PERIOD: Text('Периодичность связи (мин.)'),
        LATITUDE: Numeric('Коор. (широта)', -90, 90, /^\-?\d{0,2}(\.?\d{0,6})?$/), //eslint-disable-line
        LONGITUDE: Numeric('Коор. (долгота)', -180, 180, /^\-?\d{0,3}(\.?\d{0,6})?$/), //eslint-disable-line
        COMMENT: TextArea('Служебный комментарий'),
        PRIM: TextArea('Примечание'),
        MODEL_ID: {},
        DATE_OF_MANUFACTURE: DatePicker('Дата производства'),
      },
      postData: {},
      mapModalShow: false,
      thisTabId: null,
      zoneModalShow: false,
      lsModalShow: false,
      leakageModalShow: false
    }

    this.id = props.id //для обновления ID
  }

  clickModalOpen = (modalVariableShow, id = null) => {
    this.setState({ thisTabId: id, [modalVariableShow]: true })
  }

  hideModals = () => {
    this.setState({
      thisTabId: null,
      zoneModalShow: false,
      lsModalShow: false,
      leakageModalShow: false
    })
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{`${this.id ? 'Редактирование' : 'Создание'} запорной арматуры NBIOT`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Guid key={this.id} value={this.id} />
            </Row>
            <Row>
              {FormControlsRender('SERIAL_NUMBER', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
              <SearchInputObj
                label="Модель"
                source="valve-model"
                size='4'
                server={this.props.server}
                render={item => <>{item.TITLE}</>}
                onSelectItem={item => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, { MODEL_ID: item.ID ?? null })
                  })
                }}
                defaultItem={this.state.card?.MODEL ?? {}}
                errorMessage={this.state.formControls.MODEL_ID?.errorMessage}
              />
              {FormControlsRender('DATE_OF_MANUFACTURE', this.state.formControls, this.onChangeDPHandler, this.onSaveHandler(), 'col-md-4')}
            </Row>
            <Row>
              {FormControlsRender('IMEI', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
              {FormControlsRender('ICCID', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
              {FormControlsRender('EXTERNAL_ID', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
            </Row>
            <Row>
              {FormControlsRender('PERIOD', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
              {FormControlsRender('LATITUDE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-3')}
              {FormControlsRender('LONGITUDE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-3')}
              <div className="col-md-2"><Button variant="secondary" style={{ marginTop: "32px" }} onClick={this.showMapModal()}>Карта</Button></div>
            </Row>
            <Row>
              <SearchInputAddr
                key={this.state.card?.ADDRESS?.ID ?? 'address'}
                size='12'
                server={this.props.server}
                closeSearch={this.closeSearchAddr}
                defaultItem={this.state.card?.ADDRESS ?? {}}
                onSelectItem={item => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, { ADDRESS: item.ID ?? null })
                  })
                }}
                addAddr={this.addAddr}
              />
            </Row>
            <Row>
              {FormControlsRender('PRIM', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-6')}
              {FormControlsRender('COMMENT', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-6')}
            </Row>
          </Form>

          <Nav variant="tabs" defaultActiveKey={this.state.navKey} onSelect={key => this.setState({ navKey: key })}>
            <Nav.Item>
              <Nav.Link eventKey="1">Зоны</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="2">ЛС</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="3">Датчики протечки</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="4">Дополнительно</Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="tab-content tabContent" id="nav-tabContent">
            <span style={{ display: this.state.navKey !== '1' ? 'none' : null }}>
              <Tab
                cards={this.state.card?.ZONES ?? []}
                onChangeHandler={() => this.loadAndParentReload(this.id, ['ZONES'])}
                renderFunction={item => <Zone item={item} onClick={this.clickModalOpen.bind(this, 'zoneModalShow', item.ID)} />}
                renderSearchFunction={item => <Zone item={item} />}
                addUrl={`retranslate/${this.props.server}/zone-valve-nbiot`}
                addDataIn={item => ({ VALVE_ID: this.id, ZONE_ID: item.ID })}
                deleteUrl={item => (`retranslate/${this.props.server}/zone-valve-nbiot/${item.ID_LINK}`)}
                searchUrl={`retranslate/${this.props.server}/zone`}
                filter={{ noValveNbiot: this.id ?? null }}
                disabled={!this.id}
              />
            </span>
            <span style={{ display: this.state.navKey !== '2' ? 'none' : null }}>
              <Tab
                cards={this.state.card?.LS ?? []}
                onChangeHandler={() => this.loadAndParentReload(this.id, ['LS'])}
                renderFunction={item => <LS item={item} onClick={this.clickModalOpen.bind(this, 'lsModalShow', item.ID)} />}
                renderSearchFunction={item => <LS item={item} />}
                addUrl={`retranslate/${this.props.server}/lic-valve-nbiot`}
                addDataIn={item => ({ VALVE: this.id, LIC_SCHET: item.ID })}
                deleteUrl={item => (`retranslate/${this.props.server}/lic-valve-nbiot/${item.ID_LINK}`)}
                searchUrl={`retranslate/${this.props.server}/lic-schet`}
                filter={{ noValveNbiot: this.id ?? null }}
                disabled={!this.id}
              />
            </span>
            <span style={{ display: this.state.navKey !== '3' ? 'none' : null }}>
              <Tab
                cards={this.state.card?.LEAKAGES ?? []}
                onChangeHandler={() => this.loadAndParentReload(this.id, ['LEAKAGES'])}
                renderFunction={item => <LeakageSensor item={item} onClick={this.clickModalOpen.bind(this, 'leakageModalShow', item.ID)} />}
                renderSearchFunction={item => <LeakageSensor item={item} />}
                addUrl={`retranslate/${this.props.server}/leakage-sensor-valve-nbiot`}
                addDataIn={item => ({ VALVE_ID: this.id, SENSOR_ID: item.ID })}
                deleteUrl={item => (`retranslate/${this.props.server}/leakage-sensor-valve-nbiot/${item.ID_LINK}`)}
                searchUrl={`retranslate/${this.props.server}/leakage-sensor`}
                filter={{ noValve: this.id ?? null }}
                disabled={!this.id}
              />
            </span>
            <span style={{ display: this.state.navKey !== '4' ? 'none' : null }}>
              {FormControlsRender('HW_VER', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-2')}
              {FormControlsRender('SW_VER', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-2')}
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.id ? <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button> : null}
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
          <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.cancelClick}>Отмена</Button>
        </Modal.Footer>

        <Modal show={this.state.mapModalShow} onHide={this.hideMapModal()} size="xl">
          <Map
            coordinates={[this.state.formControls.LATITUDE.value, this.state.formControls.LONGITUDE.value]}
            hideCallback={coordinates => {
              this.coordinatesToState(coordinates)
              this.hideMapModal()()
            }}
          />
        </Modal>

        <Modal show={this.state.zoneModalShow} onHide={this.hideModals} size="xl">
          <ZoneForm
            server={this.props.server}
            id={this.state.thisTabId}
            hideCallback={this.hideModals}
            parentReloadCallback={() => this.loadAndParentReload(this.id, ['ZONES'])}
            mainUrl='zone' />
        </Modal>

        <Modal show={this.state.lsModalShow} onHide={this.hideModals} size="xl">
          <LsForm
            server={this.props.server}
            id={this.state.thisTabId}
            hideCallback={this.hideModals}
            parentReloadCallback={() => this.loadAndParentReload(this.id, ['LS'])}
            mainUrl='lic-schet' />
        </Modal>

        <Modal show={this.state.leakageModalShow} onHide={this.hideModals} size="xl">
          <LeakageForm
            server={this.props.server}
            id={this.state.thisTabId}
            hideCallback={this.hideModals}
            parentReloadCallback={() => this.loadAndParentReload(this.id, ['LEAKAGES'])}
            mainUrl='leakage-sensor' />
        </Modal>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    )
  }
}