import BaseForm from '../BaseForm'

import React from 'react'
import { Button, Modal, Row } from 'react-bootstrap'

import FormControlsRender from '../../../Utils/FormControlsRender'
import { Text, TextArea, DatePicker } from '../../../Utils/FormControlsPresets'
import SearchInputObj from '../../../Components/General/SearchInputs/searchInputObj'
import SearchInputAddr from '../../../Components/General/SearchInputs/searchInputAddr'
import Guid from '../../../Components/Guid'
import ConfirmModal from '../../../Components/General/ConfirmModal'

export default class ContractorForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      requestProcess: false,
      card: {},
      formControls: {
        TITLE: Text('Наименование'),
        CONTACTS: TextArea('Контакты'),
        PRIM: TextArea('Примечание'),
        DATEON: DatePicker('Дата создания записи'),
        TYPE: {}
      },
      postData: {}
    }

    this.id = props.id //для обновления ID
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{`${this.id ? 'Редактирование' : 'Создание'} контрагента`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Guid key={this.id} value={this.id} />
          </Row>
          <Row>
            {FormControlsRender('TITLE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-8')}

            <SearchInputObj
              label="Категория"
              source="contractor-type"
              size='4'
              server={this.props.server}
              render={item => <>{item.TITLE}</>}
              onSelectItem={item => {
                this.setState({
                  postData: Object.assign({}, this.state.postData, { TYPE_ID: item.ID ?? null })
                })
              }}
              defaultItem={this.state.card?.TYPE ?? {}}
              errorMessage={this.state.formControls.TYPE?.errorMessage}
            />
          </Row>
          <Row>
            <SearchInputAddr
              key={this.state.card?.ADDRESS?.ID ?? 'address'}
              size='8'
              server={this.props.server}
              closeSearch={this.closeSearchAddr}
              defaultItem={this.state.card?.ADDRESS ?? {}}
              onSelectItem={item => {
                this.setState({
                  postData: Object.assign({}, this.state.postData, { ADDRESS: item.ID ?? null })
                })
              }}
              addAddr={this.addAddr}
            />
            {FormControlsRender('DATEON', this.state.formControls, this.onChangeDPHandler, this.onSaveHandler(), 'col-md-4', { disabled: true })}
          </Row>
          <Row>
            {FormControlsRender('CONTACTS', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-6')}
            {FormControlsRender('PRIM', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-6')}
          </Row>

        </Modal.Body>
        <Modal.Footer>
          {this.id ? <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button> : null}
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
          <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.cancelClick}>Отмена</Button>
        </Modal.Footer>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    )
  }
}