import BaseItem from '../BaseItem'

import React from 'react'

export default class ContractItem extends BaseItem {

    render() {
        return (
            <tr onClick={this.onClick.bind(this, this.props.info.ID)} style={{ cursor: 'pointer' }}>
                <th>
                    {this.props.info.PARENT?.TITLE ? <i className="fas fa-fw"> </i> : ''}
                    {this.props.info.TITLE}
                </th>
                <td>
                    {this.props.info.CONTRACTOR?.TITLE ?? ''}
                </td>
                <td>
                    {this.props.info.TYPE?.TITLE ?? ''}
                </td>
                <td>
                    {this.props.info.ZONE?.TITLE ?? ''}
                </td>
                <td>
                    <div>
                        <i className="fas fa-fw fa-clipboard" title="Скопировать GUID"
                            onClick={this.copyCB.bind(this, this.props.info.ID)}> </i>
                    </div>
                </td>
            </tr>
        )
    }
}