import BaseItem from '../inServer/BaseItem'

import React from 'react'
import { NavLink } from 'react-router-dom'

export default class ServerItem extends BaseItem {

    render() {
        const permissions = JSON.parse(localStorage.getItem('permissions') ?? null)
        const onClick = permissions?.server ? this.onClick.bind(this, this.props.info.id) : null
        const cursorStyle = permissions?.server ? { cursor: 'pointer' } : null

        return (
            <tr>
                <td onClick={onClick} style={cursorStyle}>
                    {this.props.info.code}
                </td>

                <th onClick={onClick} style={cursorStyle}>
                    <NavLink to={'/server/' + this.props.info.id}>{this.props.info.name}</NavLink>
                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>
                        <span title="GUID">{this.props.info.id}</span></div>
                </th>

                <td>
                    <div className="btn-group" role="group">
                        <a style={this.props.info.spo_url ? {color: 'black'} : { pointerEvents: 'none', cursor: 'default', textDecoration: 'none', color: '#6c757d' }}
                            href={this.props.info.spo_url} target="_blank" rel="noopener noreferrer" title="Перейти в Сервисное ПО"
                            className="btn btn-outline-secondary">СПО</a>
                        <a style={this.props.info.kpo_url ? {color: 'black'} : { pointerEvents: 'none', cursor: 'default', textDecoration: 'none', color: '#6c757d' }}
                            href={this.props.info.kpo_url} target="_blank" rel="noopener noreferrer" title="Перейти в Клиентское ПО"
                            className="btn btn-outline-secondary">КПО</a>
                    </div>
                </td>
            </tr >
        )
    }
}