export const permissions = {
    abonent: 'abonent',
    abonentTypeCounter: 'abonent-type-counter',
    accelerationSensor: 'acceleration-sensor',
    accelerationSensorModel: 'acceleration-sensor-model',
    address: 'address',
    bank: 'bank',
    balanceBalance: 'balance-balance',
    balanceCounter: 'balance-counter',
    balanceGroup:'balance-group',
    classCounter: 'class-counter',
    classModem: 'class-modem',
    client: 'client',
    complectModem: 'complect-modem',
    contract: 'contract',
    contractCounter: 'contract-counter',
    contractLeakageSensor: 'contract-leakage-sensor',
    contractModem: 'contract-modem',
    contractSensor: 'contract-sensor',
    contractType: 'contract-type',
    contractValveNbiot: 'contract-valve-nbiot',
    contractor: 'contractor',
    contractorType: 'contractor-type',
    counter: 'counter',
    counterPlan: 'counter-plan',
    guardSensor: 'guard-sensor',
    guardSensorModel: 'guard-sensor-model',
    leakageSensor: 'leakage-sensor',
    leakageSensorPlan: 'leakage-sensor-plan',
    leakageSensorValveNbiot: 'leakage-sensor-valve-nbiot',
    licCounter: 'lic-counter',
    licLeakageSensor: 'lic-leakage-sensor',
    licSchet: 'lic-schet',
    licValveNbiot: 'lic-valve-nbiot',
    locale: 'locale',
    magneticContactSensor: 'magnetic-contact-sensor',
    magneticContactSensorModel: 'magnetic-contact-sensor-model',
    mkd: 'mkd',
    mkdCounter: 'mkd-counter',
    mkdModem: 'mkd-modem',
    modem: 'modem',
    modemCounter: 'modem-counter',
    org: 'org', 
    plan: 'plan',
    resource: 'resource',
    rezonModem: 'rezon-modem',
    sensor: 'sensor',
    sensorModel: 'sensor-model',
    sensorPlan: 'sensor-plan',
    telecomLs: 'telecom-ls',
    telecomOperator: 'telecom-operator',
    typeCounter: 'type-counter',
    typePoint: 'type-point',
    valve: 'valve',
    valveNbiot: 'valve-nbiot',
    valveNbiotPlan: 'valve-nbiot-plan',
    valveModel: 'valve-model',
    zone: 'zone',
    zoneAccelerationSensor: 'zone-acceleration-sensor',
    zoneCounter: 'zone-counter',
    zoneGuardSensor: 'zone-guard-sensor',
    zoneLeakageSensor: 'zone-leakage-sensor',
    zoneMagneticContactSensor: 'zone-magnetic-contact-sensor',
    zoneSensor: 'zone-sensor',
    zoneValve: 'zone-valve',
    zoneValveNbiot: 'zone-valve-nbiot',
    weatherSource: 'weather-source',
    webModule: 'web-module',
    webModuleAbonent: 'web-module-abonent',
}

export function canRead(name, permissions) {
    return can(name, permissions, 1)
  }
  
  export function canCreate(name, permissions) {
    return can(name, permissions, 2)
  }
  
  export function canUpdate(name, permissions) {
    return can(name, permissions, 4)
  }
  
  export function canDelete(name, permissions) {
    return can(name, permissions, 8)
  }
  
  export function canAll(name, permissions) {
    return can(name, permissions, 15)
  }

  function can(name, permissions, action) {
    if (!permissions) {
        return true    
    }
    
    const userPermissions = permissions ?? []
    const userPermission = userPermissions[name] ?? 0
    return userPermission ? (userPermission & action) === action : false
  }