import React from 'react'
import { Alert, Button } from 'react-bootstrap'

export default function Error(props) {
    const text = props.text ?? 'Ошибка получения данных с сервера.'
    const buttonText = props.buttonText ?? 'обновить данные'

    return (
        <Alert variant="danger">
            {text}
            <Button onClick={() => props.onClick()} variant="dark" style={{marginLeft: '10px'}}>
                {buttonText}
            </Button>
        </Alert>
    )
}