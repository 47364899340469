import React from 'react'

export default function Zone(props) {
    const item = props.item

    return (
        item.TITLE ?
            <span
                onClick={props.onClick ?? null}
                style={props.onClick ? { cursor: 'pointer' } : null}
                title={props.onClick ? 'Просмотреть' : null}>
                <span className={"app-icons app-icon-" + item.TYPE_POINT_IMAGE_NUMBER + " app-icons-top"} title={item.TYPE_POINT_TITLE}> </span>
                &nbsp;
                {item.TITLE} {item.PARENT_TITLE ? ` | ${item.PARENT_TITLE}` : ''}
            </span> : ''
    )
}