import React, { useState, useEffect } from 'react'
import { RequestWithToken } from './Request'
import SearchForTab from "./SearchInputs/searchForTab"
import { showNotificationErrors } from '../../Utils/Helpers'
import ConfirmModal from './ConfirmModal'
import GroupAdd from './GroupAdd/GroupAdd'
import { getObjHashCode } from '../../Utils/Helpers'
import { toast } from 'react-toastify'

export default function Tab(props) {
    const [showGroupAdd, setShowGroupAdd] = useState(false)
    const [cards, setCards] = useState(props.cards ?? [])
    const [addForm, setAddForm] = useState(false)
    const [confirmModal, setConfirmModal] = useState({})

    const [count, setCount] = useState(props.cards?.length ?? 0)

    useEffect(() => {
        setCards(props.cards ?? [])
        setCount(props.cards?.length ?? 0)
        // eslint-disable-next-line
    }, [getObjHashCode(props.cards ?? [])])

    const addItemLoad = item => {
        RequestWithToken(props.addUrl)
            .post(props.addDataIn(item))
            .then(() => {
                if (props.onChangeHandler) {
                    props.onChangeHandler()
                }
                toast.success('Успешно')
            })
            .catch(() => {
                showNotificationErrors('Ошибка сохранения')
                setCards([])
            })
    }

    const delItemLoad = item => {
        RequestWithToken(props.deleteUrl(item))
            .del(props.deleteDataIn ? props.deleteDataIn(item) : {})
            .then(() => {
                if (props.onChangeHandler) {
                    props.onChangeHandler()
                }
                toast.success('Успешно')
            })
            .catch(() => {
                showNotificationErrors('Ошибка удаления')
            })
    }

    const keyUpItem = item => event => {
        if (event.keyCode === 13) {
            addItemLoad(item)
        }
    }

    const addItem = item => {
        setAddForm(false)

        if (props.onSearchSelect) {
            props.onSearchSelect(item)
        } else {
            addItemLoad(item)
        }
    }

    const delItem = item => {
        setConfirmModal({
            text: 'Вы действительно хотите удалить?',
            handleClose: () => setConfirmModal(),
            handleAccept: () => {
                setConfirmModal()
                delItemLoad(item)
            }
        })
    }

    const addClick = () => {
        setAddForm(true)
    }

    const closeSearch = () => {
        setAddForm(false)
    }

    return (
        <span style={{fontWeight: 'normal'}}>
            {addForm ?
                <SearchForTab
                    server={props.server}
                    url={props.searchUrl}
                    closeSearch={closeSearch}
                    newClick={props.newClick ?? null}
                    owner={props.owner}
                    directory={props.directory ?? null}
                    filter={props.filter}
                    renderFunction={item => {
                        return (
                            <div
                                onClick={() => addItem(item)}
                                onKeyUp={keyUpItem(item)}
                                key={item.ID ?? item.id}
                                tabIndex={0}
                            >
                                <span className="pointer" title="Добавить">
                                    {props.renderSearchFunction(item)}
                                </span>
                            </div>
                        )
                    }} />
                :
                <div>
                    {props.addUrl ?
                        <button type="button" className="btn btn-success btnAddSmallu"
                            title="Добавить" onClick={addClick}><i className="fas fa-plus-circle"> </i>
                        </button> : null}
                    {props.groupAddUrl ?
                        <button type="button" className="btn btn-info btnAddSmallu"
                            title="Групповое добавление устройств" onClick={() => setShowGroupAdd(true)}>
                            <i className="fas fa-file-circle-plus"> </i>
                        </button> : null}
                    <span style={{ float: 'right' }}>{count > 0 ? ('Записей: ' + count) : null}</span>
                </div>

            }

            {props.disabled ?
                <div className="splash-block"> Для работы с этим блоком нажмите "Сохранить и продолжить"</div>
                : cards.length > 0 ? null : <div style={{ textAlign: 'center' }}>{props.textForEmpty ?? ''}</div>}
            <table className="table table-hover table-sm">
                <tbody>
                    {cards.map(item => {
                        return (
                            <tr key={item.ID ?? item.id} >
                                {props.deleteUrl ?
                                    <td style={{ width: '30px' }}>
                                        <button type="button" className="btn btn-danger btnAddSmall2"
                                            title="Удалить" onClick={() => delItem(item)}><i className="fas fa-times-circle"> </i>
                                        </button>
                                    </td>
                                    : null}
                                <td>{props.renderFunction(item)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {showGroupAdd ? <GroupAdd
                onChange={props.onChangeHandler}
                onHide={() => setShowGroupAdd(false)}
                url={props.groupAddUrl} /> : null}

            <ConfirmModal {...confirmModal} />
        </span>
    )
}