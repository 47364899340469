import BaseForm from '../BaseForm'

import React from 'react'
import { Button, Form, Modal, Image, Row } from 'react-bootstrap'

import FormControlsRender from '../../../Utils/FormControlsRender'
import { Numeric, DatePicker } from '../../../Utils/FormControlsPresets'
import SearchInputObj from '../../../Components/General/SearchInputs/searchInputObj'
import Guid from '../../../Components/Guid'
import ConfirmModal from '../../../Components/General/ConfirmModal'

import styles from '../../../Components/Map/Map.module.css'

export default class DevicePlanForm extends BaseForm {
  constructor(props) {
    super(props)

    this.devField = props.devField
    this.devicePos = null
    this.coord = null
    this.id = props.id //для обновления ID

    this.state = {
      requestProcess: false,
      card: props.card ?? {},
      formControls: {
        TOP: Numeric('Отступ сверху', 1, 100, /^[1-9]\d*$/, '', '50'),
        LEFT: Numeric('Отступ слева', 1, 100, /^[1-9]\d*$/, '', '50'),
        DATEON: DatePicker('Дата создания записи')
      },
      postData: this.id ? {} : { PLAN_ID: this.props.card.PLAN?.ID, [this.devField]: this.props.card.DEVICE?.ID, TOP: 50, LEFT: 50 },
      modalShow: false,
    }
  }

  onSelectDev = item => {
    this.setState({ postData: Object.assign({}, this.state.postData, { [this.devField]: item.ID ?? null }) })
  }

  modalSubmitClick = () => {
    let formControls = this.state.formControls
    let postData = this.state.postData
    if (this.devicePos) {
      formControls.TOP.value = this.devicePos.top
      postData.TOP = this.devicePos.top
      formControls.LEFT.value = this.devicePos.left
      postData.LEFT = this.devicePos.left
    }
    this.setState({
      formControls,
      postData,
      modalShow: false,
    })
  }

  showModal = () => {
    this.setState({ modalShow: true })
  }

  hideModal = () => {
    this.setState({ modalShow: false })
  }

  allowDrop = e => {
    e.preventDefault()
  }

  onDragEnd = e => {
    const parent = e.target.parentElement.getBoundingClientRect()
    const diffY = this.coord.top - e.screenY
    const top = e.target.offsetTop - diffY + e.target.offsetHeight / 2
    const topPers = top / parent.height * 100

    const diffX = this.coord.left - e.screenX
    const left = e.target.offsetLeft - diffX + e.target.offsetWidth / 2
    const leftPers = left / parent.width * 100

    if (topPers < 0 || topPers > 100 || leftPers < 0 || leftPers > 100) {
      e.preventDefault()
      return false
    }

    e.target.style.top = topPers + '%'
    e.target.style.left = leftPers + '%'

    this.devicePos = { top: Math.round(topPers), left: Math.round(leftPers) }
  }

  getDeviceClass = icon => {
    switch (icon) {
      case 'sensor':
        return styles.sensorOnPlan
      case 'leakage-sensor':
        return styles.leakageSensorOnPlan
      case 'valve-nbiot':
        return styles.valveNbiotOnPlan
      case 'electro':
        return styles.counterElectroOnPlan
      case 'w-cold':
        return styles.counterColdOnPlan
      case 'w-hot':
        return styles.counterHotOnPlan
      case 'gaz':
        return styles.counterGazOnPlan
      case 'colorimetr':
        return styles.counterColorOnPlan
      default:
        return styles.counterElectroOnPlan
    }
  }

  RenderDevicesOnPlan = props => {
    return <>
      {(props.items ?? []).map(item => {
        return item.ID === this.state.card.DEVICE?.ID ? null :
          <div
            key={item.ID}
            className={this.getDeviceClass(item.ICON)}
            title={item.SERIAL_NUMBER ?? item.CODE}
            style={{ top: `${item.TOP}%`, left: `${item.LEFT}%`, opacity: '0.5', cursor: 'default' }} />
      })}
    </>
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{`${this.id ? 'Редактирование' : 'Создание'} записи "План-устройство"`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Row>
              <Guid key={this.id} value={this.id} />
            </Row>

            <Row>
              <SearchInputObj
                size={4}
                label="План"
                source="plan"
                server={this.props.server}
                onSelectItem={item => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, { PLAN_ID: item.ID ?? null })
                  })
                }}
                defaultItem={this.state.card.PLAN}
              />

              <SearchInputObj
                size={4}
                label="Устройство"
                source={this.props.devUrl}
                server={this.props.server}
                render={item => <>{item.SERIAL_NUMBER ?? item.CODE}</>}
                onSelectItem={this.onSelectDev}
                defaultItem={this.state.card.DEVICE}
              />

              {FormControlsRender('DATEON', this.state.formControls, this.onChangeDPHandler, this.onSaveHandler(), 'col-md-4', { disabled: true })}

            </Row>

            <Row>
              {FormControlsRender('LEFT', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
              {FormControlsRender('TOP', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
              <div className="col-md-2"><Button variant="secondary" style={{ marginTop: "32px" }} onClick={this.showModal}>План</Button></div>
            </Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          {this.id ? <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button> : null}
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
          <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.cancelClick}>Отмена</Button>
        </Modal.Footer>

        <Modal show={this.state.modalShow} onHide={this.hideModal} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Расположение устройства</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ position: 'relative', textAlign: 'center', width: '900px', margin: '0 auto' }}>
              <Image src={this.props?.image?.url} style={{ width: '100%' }} onDragOver={this.allowDrop} />
              <this.RenderDevicesOnPlan items={this.state.card.SENSORS} />
              <this.RenderDevicesOnPlan items={this.state.card.LEAKAGE_SENSORS} />
              <this.RenderDevicesOnPlan items={this.state.card.VALVES_NBIOT} />
              <this.RenderDevicesOnPlan items={this.state.card.COUNTERS} />
              <div className={this.getDeviceClass(this.state.card.DEVICE?.ICON)}
                style={{ top: `${this.state.formControls.TOP.value ?? 50}%`, left: `${this.state.formControls.LEFT.value ?? 50}%` }}
                draggable="true"
                title={this.state.card.DEVICE?.SERIAL_NUMBER ?? this.state.card.DEVICE?.CODE}
                onDragStart={e => { this.coord = { top: e.screenY, left: e.screenX } }}
                onDragEnd={this.onDragEnd}
              ></div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.modalSubmitClick}>Применить</Button>
            <Button variant="secondary" onClick={this.hideModal}>Отмена</Button>
          </Modal.Footer>
        </Modal>

        <ConfirmModal {...this.state.confirmModal}/>
      </>
    )
  }
}