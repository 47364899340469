import React, { Component } from 'react'
import MkdStage from "./mkdStage"
import MkdBsItem from "./mkdBsItem"
import MkdCounterItem from "./mkdCounterItem"
import { RequestWithToken } from '../General/Request'
import { toast } from 'react-toastify'
import CounterForm from '../../Modules/inServer/counters/CounterForm'
import ModemForm from '../../Modules/inServer/modems/ModemForm'
import { Modal } from 'react-bootstrap'

export default class MkdTable extends Component {

  state = {
    modalActTitle: '',
    searchText: '',
    thisModem: '',
    thisCounter: '',
    showCounterModal: false,
    showBsModal: false
  }

  refThead = React.createRef()

  async counterAdd(cell, counter) {
    RequestWithToken(`retranslate/${this.props.server}/mkd-counter`)
      .post({ "SCHEME_ID": cell, "COUNTER_ID": counter })
      .then(() => {
        toast.success('Перемещено', null, 200)
        this.props.load()
      })
      .catch(() => toast.error('Ошибка перемещания'))
  }

  async counterUpd(cell, counter, linkId) {
    RequestWithToken(`retranslate/${this.props.server}/mkd-counter/${linkId}`)
      .put({"SCHEME_ID": cell, "COUNTER_ID": counter})
      .then(() => this.props.load())
      .catch(() => toast.error('Ошибка перемещания'))
  }

  async counterDel(linkId) {
    RequestWithToken(`retranslate/${this.props.server}/mkd-counter/${linkId}`)
      .del()
      .then(() => this.props.load())
      .catch(() => toast.error('Ошибка перемещания'))
  }


  async bsAdd(cell, bs) {
    RequestWithToken(`retranslate/${this.props.server}/mkd-modem`)
      .post({ "SCHEME_ID": cell, "MODEM_ID": bs })
      .then(() => this.props.load())
      .catch(() => toast.error('Ошибка перемещания'))
  }

  async bsUpd(cell, bs, linkId) {
    RequestWithToken(`retranslate/${this.props.server}/mkd-modem/${linkId}`)
      .put({ "SCHEME_ID": cell, "MODEM_ID": bs })
      .then(() => this.props.load())
      .catch(() => toast.error('Ошибка перемещания'))
  }

  async bsDel(linkId) {
    RequestWithToken(`retranslate/${this.props.server}/mkd-modem/${linkId}`)
      .del()
      .then(() => this.props.load())
      .catch(() => toast.error('Ошибка перемещания'))
  }

  SearchInput = event => {
    ///this.load(event.target.value)
    this.setState({
      searchText: event.target.value,
    })
  }

  clearSearch = () => {
    this.setState({
      searchText: '',
    })
  }

  SearchKeyUp = event => {
    if (event.keyCode === 13) {
      this.search()
    }
  }

  search = () => {

  }

  CellClick = () => {

  }

  PodClick = () => {
    this.setState({ thisZone: '' })
    this.setState({ ClickN: Math.random() })
    this.setState({ modalActTitle: "Действия с подъездом" })
  }

  StageClick = () => {
    this.setState({ thisZone: '' })
    this.setState({ ClickN: Math.random() })
    this.setState({ modalActTitle: "Действие с этажом" })
  }

  CounterClick = (counterId) => {
    this.setState({ thisCounter: counterId, showCounterModal: true })
  }

  BsClick = (bsId) => {
    this.setState({ thisModem: bsId, showBsModal: true })
  }

  allowDrop = e => {
    e.preventDefault()
  }

  DallowDrop = e => {
    e.stopPropagation()
  }

  drag = e => {
    e.dataTransfer.setData("text", e.target.id)
  }

  dragend = e => {
  }

  drop = e => {
    e.preventDefault()
    var data = e.dataTransfer.getData("text")
    var unit = data.split('|')
    var cell_id = e.target.id
    var type_unit = unit[0]
    var type_parrent = unit[1]
    var unit_id = unit[2]
    var id_link = unit[3]

    if (type_parrent === 'cell') {
      if (type_unit === 'c') {
        this.counterUpd(cell_id, unit_id, id_link)
      }
      if (type_unit === 'b') {
        this.bsUpd(cell_id, unit_id, id_link)
      }
    }

    if (type_parrent === 'no') {
      if (type_unit === 'c') {
        this.counterAdd(cell_id, unit_id)
      }
      if (type_unit === 'b') {
        this.bsAdd(cell_id, unit_id)
      }
    }
  }

  dropClr = e => {
    e.preventDefault()
    var data = e.dataTransfer.getData("text")
    //e.target.appendChild(document.getElementById(data))
    var unit = data.split('|')
    var type_unit = unit[0]
    var type_parrent = unit[1]
    var id_link = unit[3]

    if (type_parrent === 'cell') {
      if (type_unit === 'c') {
        this.counterDel(id_link)
      }
      if (type_unit === 'b') {
        this.bsDel(id_link)
      }
    }
  }

  hideModal = () => () => {
    this.setState({ showCounterModal: false, showBsModal: false })
  }

  /**
   * Фиксация шапки и левой колонки схемы МКД
   * @returns 
   */
  onScrollScheme = () => event => {
    const top = event.target.scrollTop
    const left = event.target.scrollLeft

    const theadNode = this.refThead.current
    theadNode.style.transform = `translate(0,${top}px)`

    for (let etazh of this.etazhi) {
      etazh.style.transform = `translate(${left}px)`
    }
  }

  componentDidMount() {
    this.etazhi = document.getElementsByClassName("mkdStage")
  }

  render() {
    this.cards_list = []
    for (var scheme_y in this.props.data.scheme) {
      this.cards_list.push({
        N: scheme_y,
        IN: this.props.data.scheme[scheme_y],
      })
    }

    this.podezdy = []
    for (var pod in this.props.data.podezdy) {
      this.podezdy.push({
        N: pod,
        IN: this.props.data.podezdy[pod],
      })
    }

    this.counter_list = []
    for (var counter in this.props.data.counters) {
      this.counter_list.push({
        ID: this.props.data.counters[counter].ID,
        CODE: this.props.data.counters[counter].CODE ? this.props.data.counters[counter].CODE : "",
        KVARTIRA: this.props.data.counters[counter].KVARTIRA ? this.props.data.counters[counter].KVARTIRA : "",
        ICON: this.props.data.counters[counter].ICON,
        IS_BALANCE: this.props.data.counters[counter].IS_BALANCE,
        COMMENT: this.props.data.counters[counter].COMMENT,
      })
    }

    this.bs_list = []
    for (var bs in this.props.data.bs) {
      this.bs_list.push({
        ID: this.props.data.bs[bs].ID,
        CODE: this.props.data.bs[bs].CODE ? this.props.data.bs[bs].CODE : "",
        TITLE: this.props.data.bs[bs].TITLE ? this.props.data.bs[bs].TITLE : "",
        COMMENT: this.props.data.bs[bs].COMMENT,
      })
    }

    return (
      <>
        <div className="input-group mb-3 searchInputInMkdFrm">
          <input type="text" placeholder="Поиск по приборам учёта" className="form-control" value={this.state.searchText}
            onChange={this.SearchInput} onKeyUp={this.SearchKeyUp} autoFocus={true} />
          <div className="input-group-append">
            <button className="btn btn-default" type="button" onClick={this.clearSearch} title="Очистить поиск">
              <i className="fas fa-fw fa-times"></i>
            </button>
          </div>
        </div>

        <b>Всего ПУ:</b> {this.counter_list.length + this.props.data.colCounters}, <b>Установлено ПУ:</b> {this.props.data.colCounters}, <b>Не установлено
          ПУ:</b> {this.counter_list.length}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <b>Всего БС:</b> {this.bs_list.length + this.props.data.colModems}, <b>Установлено БС:</b> {this.props.data.colModems}, <b>Не установлено БС:</b> {this.bs_list.length}
        &nbsp;&nbsp;&nbsp;

        <div style={{ overflowX: 'auto', height: '550px' }} onScroll={this.onScrollScheme()}>
          <table className="mkd-table table table-bordered">
            <tbody>
              <tr style={{ backgroundColor: '#FFF' }} ref={this.refThead}>
                <td className="mkdPod mkd-poded"></td>
                {this.podezdy.map((podiezd) => {
                  return (
                    <td className="mkdPod mkd-poded" onClick={this.PodClick} key={podiezd.IN + 'pdzd'}>Подъезд {podiezd.IN}</td>
                  )
                })}
              </tr>
              {this.cards_list.map((stage) => {
                return (
                  <MkdStage stage={stage} CellClick={this.CellClick} StageClick={this.StageClick} CounterClick={this.CounterClick} BsClick={this.BsClick} key={stage.N + 'st'}
                    allowDrop={this.allowDrop} drag={this.drag} dragend={this.dragend} DallowDrop={this.DallowDrop} drop={this.drop} searchText={this.state.searchText} />
                )
              })}
              <tr>
                <td className="mkdPod mkd-poded">Без привязки <small>БС: {this.bs_list.length} ПУ: {this.counter_list.length}</small></td>
                <td colSpan={this.podezdy.length} id="Clear" onDrop={this.dropClr} onDragOver={this.allowDrop}>
                  {this.bs_list.map(bs => {
                    if ((bs.CODE.includes(this.state.searchText)) || (bs.TITLE.includes(this.state.searchText))) {
                      return (
                        <MkdBsItem parent="no" key={bs.ID + 'bs'} bs={bs} BsClick={this.BsClick}
                          onDragStart={this.drag} onDragEnd={this.dragend} onDragOver={this.DallowDrop} />
                      )
                    }
                    return null
                  })}

                  {this.counter_list.map(counter => {
                    if ((counter.CODE.includes(this.state.searchText)) || (counter.KVARTIRA.includes(this.state.searchText))) {
                      return (
                        <MkdCounterItem parent="no" counter={counter} CounterClick={this.CounterClick} key={counter.ID + 'cnt'}
                          onDragStart={this.drag} onDragEnd={this.dragend} onDragOver={this.DallowDrop} />
                      )
                    }
                    return null
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <Modal show={this.state.showCounterModal} onHide={this.hideModal()} size="xl">
          <CounterForm
            server={this.props.server}
            id={this.state.thisCounter}
            hideCallback={this.hideModal()}
            parentReloadCallback={() => this.props.load()}
            mainUrl='counter' />
        </Modal>

        <Modal show={this.state.showBsModal} onHide={this.hideModal()} size="xl">
          <ModemForm
            server={this.props.server}
            id={this.state.thisModem}
            hideCallback={this.hideModal()}
            parentReloadCallback={() => this.props.load()}
            mainUrl='modem' />
        </Modal>
      </>
    )
  }
}