import React from 'react'
import { Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const EnotFound = props => {
    return (
        <>
            <h1>Ошибка 404</h1>
            <h2>Раздел не найден</h2>
            <div style={{ marginTop: 50 }}>
                <NavLink to="/">
                    <Button variant='primary'>Вернуться на главную</Button>
                </NavLink>
            </div>
        </>
    )
}

export default EnotFound