const config = {
  lang: window.config['lang'],
  logo: window.config['logo'],
  logo_corp: window.config['logo_corp'],
  logo_top: window.config['logo_top'],
  AppName: window.config['AppName'],
  AppName2: window.config['AppName2'],
  fias: window.config['fias'],
  api_url: window.config['api_url']
}

export default config