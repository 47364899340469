import React, { useCallback, useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'

import { RequestWithToken } from '../Request'
import { showNotificationErrors, getMimeByExt, getObjHashCode } from '../../../Utils/Helpers'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { DefaultFileIcon } from '../FileIcon/index'
import ConfirmModal from '../ConfirmModal'

import styles from './FileZone.module.css'

export default function Files(props) {
    const previewTypes = ['png', 'jpg', 'pdf', 'svg']

    const [file, setFile] = useState()
    const [confirmModal, setConfirmModal] = useState()

    const [files, setFiles] = useState(props.files)
    useEffect(() => {
        setFiles(props.files ?? [])
        // eslint-disable-next-line
    }, [getObjHashCode(props.files ?? [])])

    const [mainUrl, setMainUrl] = useState(props.mainUrl)
    useEffect(() => {
        setMainUrl(props.mainUrl)
    }, [props.mainUrl])

    const uploadFiles = files => {
        let datein = new FormData()

        files.forEach(file => {
            datein.append('files[]', file, file.name)
        })

        RequestWithToken(`${mainUrl}/file`)
            .setHeaders({"Content-Type": "multipart/form-data"})
            .post(datein)
            .then(() => {
                if (props.onChangeHandler) {
                    props.onChangeHandler()
                }

                toast.success('Файл успешно загружен')
            })
            .catch(e => {
                showNotificationErrors('Ошибка загрузки файла', e.response ?? null)
            })
    }

    const loadFile = file => {
        RequestWithToken(`${mainUrl}/file/${file.name}`)
            .getFile()
            .then(data => {
                const type = data.type ?? getMimeByExt(this.state.card.IMAGE)
                const downloadedFile = new Blob([data], { type })//Build a URL from the file
                const url = URL.createObjectURL(downloadedFile)//Open the URL on new Window
                const ext = file.name.split('.').pop()

                setFile({ url, type, name: file.name, title: file.title, ext })
            })
            .catch(e => {
                showNotificationErrors('Ошибка загрузки файла', e.response ?? null)
            })
    }

    const delFile = name => {
        RequestWithToken(`${mainUrl}/file/${name}`)
            .del()
            .then(() => {
                if (props.onChangeHandler) {
                    props.onChangeHandler()
                }
                toast.success('Файл успешно удален')
            })
            .catch(e => {
                showNotificationErrors('Ошибка удаления файла', e.response ?? null)
            })
    }

    const delFileClick = name => {
        setConfirmModal({
            text: 'Вы действительно хотите удалить файл?',
            handleClose: () => setConfirmModal(),
            handleAccept: () => {
                setConfirmModal()
                delFile(name)
            }
        })
    }

    const saveImageClick = () => {
        const tempLink = document.createElement('a')
        tempLink.href = file.url
        tempLink.setAttribute('download', `${file?.title}.${file?.ext}`)
        tempLink.click()
        clearFile()
    }

    const Files = files.map(file => (
        <div key={file.name} className={styles.file}>
            <button type="button" className={`${styles.delButton} btn btn-danger btnAddSmall2`}
                title="Удалить" onClick={() => delFileClick(file.name)}><i className="fas fa-times-circle"> </i>
            </button>
            <span className={styles.icon} onClick={() => loadFile(file)}>
                <DefaultFileIcon name={file.name} />
            </span>
            <p className={styles.title}>
                {file.title}
            </p>
        </div>
    ))

    const clearFile = () => setFile({})
    // eslint-disable-next-line
    const onDrop = useCallback(uploadFiles, [mainUrl])
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop, noClick: true })

    return (
        <>
            {props.disabled ? <div className="splash-block"> Для работы с этим блоком нажмите "Сохранить и продолжить"</div> : null}

            <div {...getRootProps()} className={isDragActive ? styles.activeDropZone : styles.dropZone}>
                <input {...getInputProps()} />
                <button onClick={open} type="button" className="btn btn-success btnAddSmallu"
                    title="Добавить"><i className="fas fa-plus-circle"> </i>
                </button>
                <aside className={styles.root}>
                    {Files}
                </aside>

            </div>

            <Modal show={file?.url} onHide={clearFile} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{file?.title}.{file?.ext}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {previewTypes.includes(file?.ext) ?
                        <object type={file?.type} data={file?.url} className={styles.imageBox}>
                            Изображение
                        </object>
                        : <p>Предварительный просмотр недоступен</p>

                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={saveImageClick}>Скачать</Button>
                    <Button variant="secondary" onClick={clearFile}>Отмена</Button>
                </Modal.Footer>
            </Modal>

            <ConfirmModal {...confirmModal} />
        </>
    )

}