import React from 'react'
import BaseItem from '../BaseItem'

export default class BalanceItem extends BaseItem {
  
  type_str = this.props.info.RES_NAME
  counterIcon = icon => {
    var fa = 'fa-question'
    this.type_str = this.props.info.RES_NAME
    switch (icon) {
      case 'electro':
        fa = 'fas fa-fw res20 res-electro'
        break
      case 'stok':
        fa = 'fas fa-fw fa-tint-slash'
        break
      case 'w-cold':
        fa = 'fas fa-fw res20 res-w-cold'
        break
      case 'w-hot':
        fa = 'fas fa-fw res20 res-w-hot'
        break
      case 'gaz':
        fa = 'fas fa-fw res20 res-gaz'
        break
      case 'colorimetr':
        fa = 'fas fa-fw res20 res-colorimetr'
        break
      default:
        fa = 'fas fa-fw fa-question'
        this.type_str = 'Без типа'
    }
    return fa
  }

  render() {

    return (
      <tr onClick={this.onClick.bind(this, this.props.info.ID)}
        style={{ cursor: 'pointer' }}>
        <th title={this.props.info.ID}>
          <i className={'' + this.counterIcon(this.props.info.ICON_NAME)}
            title={this.type_str}> </i>

          {this.props.info.TITLE}
        </th>

        <td>
          <div>
            <i className="fas fa-fw fa-clipboard" title="Скопировать GUID"
              onClick={this.copyCB.bind(this, this.props.info.ID)}> </i>
          </div>
        </td>

      </tr>
    )
  }
}