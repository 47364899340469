import React from 'react'
import BaseItem from '../BaseItem'

export default class ZoneItem extends BaseItem {

    render() {
        return (
            <tr onClick={this.onClick.bind(this, this.props.info.ID)} style={{ cursor: 'pointer' }}>
                <th title={this.props.info.ID}>
                    <span className={"app-icons app-icon-" + this.props.info.TYPE_POINT_IMAGE_NUMBER + " app-icons-top"} title={this.props.info.TYPE_POINT_TITLE}> </span>
                        &nbsp;
                     {this.props.info.TITLE}
                </th>
                
                <td>
                    <small style={{ whiteSpace: 'nowrap', overflow: 'hidden', width: '50px' }}>
                        {this.props.info.PARENT_TITLE}
                    </small>
                </td>

                <td>
                    <i className="fas fa-fw fa-clipboard" title="Скопировать GUID"
                        onClick={this.copyCB.bind(this, this.props.info.ID)}> </i>&nbsp;&nbsp;
                        {this.props.info.MKD ? <i className="fas fa-fw fa-building" title="Схема МКД"> </i> : <i className="fas fa-fw"> </i>}
                    {this.props.info.COMMENT ? <i className="fas fa-fw fa-comment-dots" title={this.props.info.COMMENT}> </i> : <i className="fas fa-fw"> </i>}
                    {this.props.info.WEATHER_SOURCE ? <i className="fas fa-cloud-sun" title="Источник погоды заполнен"> </i> : <i className="fas fa-fw"> </i>}
                </td>
            </tr>
        )
    }
}