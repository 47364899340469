export function Text(label, placeholder, type = 'text') {
    return {
        value: '',
        type,
        label,
        placeholder,
        errorMessage: null,
        get tVal() { return this.value === '' ? null : String(this.value) },
    }
}

export function Password(label, placeholder = '') {
    return Text(label, placeholder, 'password')
}

export function Numeric(label, min = -99999999, max = 99999999, mask = /^[1-9]\d*$/, title = '', value = '') {
    return {
        value,
        type: 'numeric',
        errorMessage: null,
        label, min, max, mask, title,
        get tVal() { return this.value === '' ? null : Number(this.value) }
    }
}

export function TextArea(label) {
    return {
        value: '',
        type: 'textarea',
        errorMessage: null,
        label,
        get tVal() { return this.value === '' ? null : String(this.value) }
    }
}

export function Select(label, selectOptions, value = '') {
    return {
        value,
        type: 'select',
        label,
        errorMessage: null,
        selectOptions,
        get tVal() { return this.value === '' ? null : String(this.value) }
    }
}

export function Checkbox(label, type) {
    return {
        checked: false,
        type: 'checkbox',
        label,
        errorMessage: null,
        get tVal() { return this.value === '' ? null : Boolean(this.value) }
    }
}

export function DatePicker(label) {
    return {
        value: '',
        type: 'datePicker',
        label,
        errorMessage: null,
        get tVal() { return this.value === '' ? null : String(this.value) }
    }
}