import BaseForm from '../inServer/BaseForm'

import React from 'react'
import { Button, Form, Modal, /*Nav,*/ Row } from 'react-bootstrap'
import ConfirmModal from '../../Components/General/ConfirmModal'

import Permission from './Permission'
import { userCan, userCanOr } from '../../Utils/Helpers'

import SearchInputObj from '../../Components/General/SearchInputs/searchInputObj'
// import Tab from '../../Components/General/Tab'
// import Zone from '../../Components/Zone'

import {permissions as p} from './Permissions'

export default class ServerPermissionForm extends BaseForm {
    idField = 'id' //в weather он например CITY_ID


    constructor(props) {
        super(props)

        this.state = {
            requestProcess: false,
            card: {},
            postData: {},
        }

        this.id = props.id //для обновления ID
    }



    getDateIn() {
        return this.state.postData
    }

    afterLoad = (items, fields = null) => {
        let card = this.state.card

        if (fields) {
            fields.forEach(function (field) {
                card[field] = items[field]
            })
        } else {
            card = items
        }

        this.setState({
            card,
            formControls: this.getFormControls(items.permissions)
        })
    }

    allPermissions = Object.values(p)

    allowClick = () => {
        const postData = this.state.postData

        const setValue = this.allAllowed() ? 0 : 15

        this.allPermissions.map(permission => postData.permissions = Object.assign({}, postData.permissions, { [permission]: setValue }))

        this.setState({ postData })
    }

    allAllowed = () => {
        const permissions = Object.assign({}, this.state.card.permissions, this.state.postData.permissions)
        return Object.values(permissions).every(permission => permission === 15)
    }

    onChange = permissions => {
        let postData = this.state.postData
        postData.permissions = Object.assign({}, postData.permissions, ...permissions)
        this.setState({ postData })
    }

    render() {
        const buttonTitle = this.allAllowed() ? 'Запретить все' : 'Разрешить все'
        const permissions = Object.assign({}, this.state.card.permissions, this.state.postData.permissions)

        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{`${this.id ? 'Редактирование' : 'Создание'} разрешений`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={userCanOr('permission', ['update', 'create']) ? null : "disabled"}>
                    <Form>
                        <Row>
                            <SearchInputObj
                                label="Пользователь"
                                source="user"
                                onSelectItem={item => {
                                    this.setState({
                                        postData: Object.assign({}, this.state.postData, { user: item.id ?? null })
                                    })
                                }}
                                fieldKey='id'
                                defaultItem={this.state.card?.user}
                                render={item => <>{item.title}</>}
                            />
                            <SearchInputObj
                                label="Сервер"
                                source="server"
                                onSelectItem={item => {
                                    this.setState({
                                        postData: Object.assign({}, this.state.postData, { server: item.id ?? null })
                                    })
                                }}
                                fieldKey='id'
                                defaultItem={this.state.card?.server}
                                render={item => <>{item.name}</>}
                            />
                            <div className='form-group col-md-4 align-self-end'>
                                <Button className='float-end' variant="dark" onClick={this.allowClick}>{buttonTitle}</Button>
                            </div>
                        </Row>

                        <Row>
                            <Permission onChange={this.onChange} permissions={permissions} name={p.modem} label='БС и МТ'
                                aliases={[p.modemCounter,p.address,p.telecomOperator,p.rezonModem,p.classModem,p.complectModem]} />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.balanceGroup} label='Балансовые группы'
                                aliases={[p.balanceBalance,p.balanceCounter,p.typeCounter]} />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.leakageSensor} label='Датчики протечки'
                                aliases={[p.zoneLeakageSensor,p.sensorModel,p.leakageSensorValveNbiot,p.address]} />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.accelerationSensor} label='Датчики ускорения'
                                aliases={[p.zoneAccelerationSensor,p.accelerationSensorModel,p.address]} />
                        </Row>
                        <Row>
                            <Permission onChange={this.onChange} permissions={permissions} name={p.valve} label='Запорная арматура'
                                aliases={[p.zoneValve,p.valveModel, p.address]} />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.valveNbiot} label='Зап. арм. Nbiot'
                                aliases={[p.zoneValveNbiot,p.licValveNbiot,p.leakageSensorValveNbiot,p.valveModel, p.address]} />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.zone} label='Зоны'
                                aliases={[p.zoneValveNbiot,p.zoneCounter,p.zoneLeakageSensor,p.zoneValve,p.typePoint]} />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.weatherSource} label='Источники погоды' />

                        </Row>
                        <Row>
                            <Permission onChange={this.onChange} permissions={permissions} name={p.contractor} label='Контрагенты'
                                aliases={[p.contractorType, p.address]} />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.contract} label='Контракты'
                                aliases={[p.contractType,p.contractor,p.zone,p.sensor,p.contractSensor,p.leakageSensor,p.contractLeakageSensor,
                                    p.valveNbiot,p.contractValveNbiot,p.modem,p.contractModem,p.counter,p.contractCounter]} />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.licSchet} label='Лицевые счета'
                                aliases={[p.address,p.client,p.counter,p.licCounter,p.leakageSensor,p.licLeakageSensor,p.valveNbiot,p.licValveNbiot]} />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.telecomLs} label='Лицевые счета ОС'
                                aliases={[p.contract,p.telecomOperator]} />
                        </Row>
                        <Row>
                            <Permission onChange={this.onChange} permissions={permissions} name={p.magneticContactSensor} label='Магнит. датчики'
                                aliases={[p.address,p.magneticContactSensorModel,p.zone,p.zoneMagneticContactSensor]} />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.org} label='Организации'
                                aliases={[p.address,p.bank,p.resource,p.zoneMagneticContactSensor]} />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.guardSensor} label='Охранные датчики'
                                aliases={[p.address,p.guardSensorModel,p.zone,p.zoneGuardSensor]} />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.plan} label='Планы'
                                aliases={[p.sensorPlan,p.leakageSensorPlan,p.valveNbiotPlan,p.counterPlan]} />
                        </Row>
                        <Row>
                            <Permission onChange={this.onChange} permissions={permissions} name={p.client} label='Плательщики' />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.abonent} label='Пользователи'
                                aliases={[p.webModule,p.webModuleAbonent,p.typeCounter,p.abonentTypeCounter]} />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.counter} label='Приборы учета'
                                aliases={[p.address,p.typeCounter,p.classCounter,p.modem,p.locale,p.zone,p.zoneCounter,p.modem,p.modemCounter]} />
                            <Permission onChange={this.onChange} permissions={permissions} name={p.mkd} label='Схемы МКД'
                                aliases={[p.mkdCounter,p.mkdCounter,p.zone]} />
                        </Row>

                        <Row>
                            <Permission onChange={this.onChange} permissions={permissions} name={p.sensor} label='Термогигрометры'
                                aliases={[p.zoneSensor,p.sensorModel,p.address]} />
                        </Row>
                    </Form>
                    {/* <Nav variant="tabs" defaultActiveKey={1}>
                        <Nav.Item>
                            <Nav.Link eventKey="1">Зоны</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div className="tab-content tabContent">
                        <Tab
                            cards={this.state.card?.zones ?? []}
                            renderFunction={item => <div>{item}</div>}
                            // onChangeHandler={() => this.loadAndParentReload(this.id, ['ZONES'])}
                            // renderFunction={item => <Zone item={item} onClick={this.clickZoneOpen.bind(this, item.ID)} />}
                            // renderSearchFunction={item => <Zone item={item} />}
                            // addUrl={`retranslate/${this.props.server}/zone-sensor`}
                            // addDataIn={item => ({ SENSOR_ID: this.id, ZONE_ID: item.ID })}
                            // deleteUrl={item => (`retranslate/${this.props.server}/zone-sensor/${item.ID_LINK}`)}
                            // searchUrl={`retranslate/${this.props.server}/zone`}
                            // filter={{ noSensor: this.id ?? null }}
                            disabled={!this.id}
                        />
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    {this.id && userCan('permission', 'delete') ?
                        <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button>
                        : null}
                    {userCan('permission', this.id ? 'update' : 'create') ?
                        <>
                            <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
                            <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
                        </>
                        : null}
                    <Button disabled={this.state.requestProcess} variant="secondary" data-dismiss="modal" onClick={this.props.hideCallback}>Отмена</Button>
                </Modal.Footer>

                <ConfirmModal {...this.state.confirmModal} />
            </>
        )
    }
}