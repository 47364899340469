import BaseForm from '../BaseForm'

import React from 'react'
import { Button, Modal, Nav, Row } from 'react-bootstrap'

import FormControlsRender from '../../../Utils/FormControlsRender'
import { Text, TextArea, DatePicker } from '../../../Utils/FormControlsPresets'
import SearchInputObj from '../../../Components/General/SearchInputs/searchInputObj'
import Tab from '../../../Components/General/Tab'
import Guid from '../../../Components/Guid'
import ConfirmModal from '../../../Components/General/ConfirmModal'
import ModemForm from '../modems/ModemForm'
import LeakageForm from '../leakages/LeakageForm'
import ValveNbiotForm from '../valves_nbiot/ValveNbiotForm'
import Device from '../../../Components/Devices/Device'

export default class TelecomLsForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      navKey: '1',
      requestProcess: false,
      card: {},
      formControls: {
        NUMBER: Text('Номер'),
        PRIM: TextArea('Примечание'),
        DATEON: DatePicker('Дата создания записи'),
        TELECOM_OPERATOR: {},
        CONTRACT: {}
      },
      deviceModal: {},
      postData: {}
    }

    this.id = props.id //для обновления ID
  }

  devModalShow = (device) => this.setState({ deviceModal: { show: true, device } })
  devModalHide = () => this.setState({ deviceModal: {} })

  changeDateDelivery = () => e => {
    const match = e.target.value.match(/^\d{4}-\d{2}-\d{2}/)

    if (!match && e.target.value !== '') {
      return
    }

    const date = match ? match[0] : null

    let dateEnd = new Date(date)
    dateEnd.setMonth(dateEnd.getMonth() + 12)
    const textDateEnd = dateEnd.toISOString().substring(0, 10)

    const formControls = this.state.formControls
    formControls.DATE_DELIVERY.value = date
    formControls.DATE_WARRANTY_END.value = textDateEnd
    formControls.DATE_SUPPORT_END.value = textDateEnd
    formControls.DATE_TRAFFIC_END.value = textDateEnd
    this.setState({
      formControls,
      postData: Object.assign({}, this.state.postData, {
        DATE_DELIVERY: date,
        DATE_WARRANTY_END: textDateEnd,
        DATE_SUPPORT_END: textDateEnd,
        DATE_TRAFFIC_END: textDateEnd,
      })
    })
  }

  render() {
    const devicesTabMainUrl = `retranslate/${this.props.server}/${this.props.mainUrl}`

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{`${this.id ? 'Редактирование' : 'Создание'} ЛС оператора связи`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Guid key={this.id} value={this.id} />
          </Row>
          <Row>
            {FormControlsRender('NUMBER', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-6')}

            <SearchInputObj
              label="Договор"
              source="contract"
              size='6'
              server={this.props.server}
              render={item => <>{item.TITLE}</>}
              onSelectItem={item => {
                this.setState({
                  postData: Object.assign({}, this.state.postData, { CONTRACT_ID: item.ID ?? null })
                })
              }}
              filter={{ mode: 'contract' }}
              defaultItem={this.state.card?.CONTRACT ?? {}}
              errorMessage={this.state.formControls.CONTRACT?.errorMessage}
            />

            <SearchInputObj
              label="Оператор связи"
              source="telecom-operator"
              size='6'
              server={this.props.server}
              render={item => <>{item.TITLE}</>}
              onSelectItem={item => {
                this.setState({
                  postData: Object.assign({}, this.state.postData, { TELECOM_OPERATOR_ID: item.ID ?? null })
                })
              }}
              defaultItem={this.state.card?.TELECOM_OPERATOR ?? {}}
              errorMessage={this.state.formControls.TELECOM_OPERATOR?.errorMessage}
            />
            {FormControlsRender('DATEON', this.state.formControls, this.onChangeDPHandler, this.onSaveHandler(), 'col-md-6', { disabled: true })}
          </Row>
          <Row>
            {FormControlsRender('PRIM', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-12')}
          </Row>

          <Nav variant="tabs" defaultActiveKey={this.state.navKey} onSelect={key => this.setState({ navKey: key })}>
            <Nav.Item>
              <Nav.Link eventKey="1">Устройства</Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="tab-content tabContent" id="nav-tabContent">
            <span style={{ display: this.state.navKey !== '1' ? 'none' : null }}>
              <Tab
                cards={this.state.card?.DEVICES ?? []}
                onChangeHandler={() => this.loadAndParentReload(this.id, ['DEVICES'])}
                renderFunction={item => <Device device={item} onClick={this.devModalShow.bind(this, item)}/>}
                renderSearchFunction={item => <Device device={item}/>}
                addUrl={`${devicesTabMainUrl}/add-device/${this.id}`}
                addDataIn={item => ({ deviceId: item.ID })}
                groupAddUrl={`${devicesTabMainUrl}/devices-group-add/${this.id}`}
                deleteUrl={item => (`${devicesTabMainUrl}/delete-device/${item.ID}`)}
                searchUrl={`${devicesTabMainUrl}/get-devices`}
                disabled={!this.id}
              />
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.id ? <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button> : null}
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
          <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.cancelClick}>Отмена</Button>
        </Modal.Footer>

        <Modal show={this.state.deviceModal.show} onHide={this.devModalHide} size="xl">
          {this.state.deviceModal.device?.type === 'modem' ?
            <ModemForm
              server={this.props.server}
              id={this.state.deviceModal.device?.ID}
              hideCallback={this.devModalHide}
              parentReloadCallback={() => this.loadAndParentReload(this.id, ['DEVICES'])}
              mainUrl='modem' /> : null}
          {this.state.deviceModal.device?.type === 'valve' ?
            <ValveNbiotForm
              server={this.props.server}
              id={this.state.deviceModal.device?.ID}
              hideCallback={this.devModalHide}
              parentReloadCallback={() => this.loadAndParentReload(this.id, ['DEVICES'])}
              mainUrl='valve-nbiot' /> : null}
          {this.state.deviceModal.device?.type === 'leakage' ?
            <LeakageForm
              server={this.props.server}
              id={this.state.deviceModal.device?.ID}
              hideCallback={this.devModalHide}
              parentReloadCallback={() => this.loadAndParentReload(this.id, ['DEVICES'])}
              mainUrl='leakage-sensor' /> : null}
        </Modal>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    )
  }
}