import React from 'react'

import { Navbar } from 'react-bootstrap'
import '../../login.css'
import '../../menu.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import conf from '../../Config'
import MenuItem from "../../Components/Menu/MenuItem.js"
import { Navigate } from "react-router-dom"
import metadata from '../../metadata.json'
import Secret from '../Secret/Secret'
import ConfirmModal, { hideConfirmModal } from '../../Components/General/ConfirmModal'

export default class Main extends React.Component {

  state = {
    userMenu: false,
    logout: false,
  }

  toggleMenu = () => {
    const userMenu = this.state.userMenu ? false : true
    this.setState({ userMenu })
  }

  logout = () => {
    this.setState({
      confirmModal: {
        text: 'Вы действительно хотите выйти из системы?',
        handleClose: hideConfirmModal.bind(this),
        handleAccept: hideConfirmModal.bind(this, this.setState.bind(this, { logout: true })),
      }
    })
  }

  render() {
    const permissions = JSON.parse(localStorage.getItem('permissions') ?? null)

    return (
      <>
        {localStorage.title === 'secret' ? <Secret /> : <>
          {this.state.logout ? <Navigate to={'/logout'} /> : null}
          <Navbar bg="light" className="nav-bar">
            <Navbar.Brand href="/" className={'d-none d-sm-block'}><img src={conf.logo_top} alt="ПУД" /></Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <span className="userName">{localStorage.title}
                  &nbsp;&nbsp;&nbsp;
                  <span to="/logout" style={{ cursor: 'pointer' }} onClick={this.logout} title="Выход из системы"><i className="fas fa-sign-out-alt"> </i></span>
                </span>
              </Navbar.Text>
            </Navbar.Collapse>
          </Navbar>


          <div className={this.state.userMenu ? 'menu-left menu-left-open' : 'menu-left'}>
            {this.state.userMenu ?
              <i className="item"><span className={'fas fa-fw fa-toggle-on'} title="Свернуть меню" onClick={this.toggleMenu}> </span></i>
              :
              <i className="item"><span className={'fas fa-fw fa-toggle-off'} title="Развернуть меню" onClick={this.toggleMenu}> </span></i>
            }

            <hr />
            {/* <MenuItem name="Ситуационный центр" to="/dashboard" icon="fa-chart-line" /> */}
            {permissions?.user ? <MenuItem name="Пользователи ПУД" to="/users" icon="fa-user-alt" /> : null}
            {permissions?.module ? <MenuItem name="Справочники" to="/modules" icon="fa-briefcase" /> : null}
            {permissions?.permission ? <MenuItem name="Разрешения" to="/server-permissions" icon="fa-unlock" /> : null}
            <MenuItem name="Серверы" to="/servers" icon="fa-server" />
            <hr />
            <MenuItem name="Руководство" title="Руководство по эксплуатации" to="/guide" icon="fa-question-circle" />
            <MenuItem name="О системе" to="/about" icon="fa-info-circle" />
            <div className="menu-left-bottom">
              <img src={conf.logo_corp} style={{ width: '50px' }} alt="" />
              <div className="version" title={new Date(document.lastModified).toLocaleString('ru-RU')}>
                <div>версия<br />{`${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`}</div>
              </div>
            </div>
          </div>
          <div className={this.state.userMenu ? 'content content-open' : 'content'}>
            {this.props.children}
          </div>
        </>
        }
        <ConfirmModal {...this.state.confirmModal} />
      </>
    )
  }
}