import BaseItem from '../inServer/BaseItem'

import React from 'react'

export default class ModuleItem extends BaseItem {

    render() {
        return (
            <tr onClick={this.onClick.bind(this, this.props.info.id)} style={{ cursor: 'pointer' }}>
                <th>
                    {this.props.info.name}
                </th>

                <td>
                    {this.props.info.action}
                </td>


                <td>
                    <div>
                        <i className="fas fa-fw fa-clipboard" title="Скопировать GUID"
                            onClick={this.copyCB.bind(this, this.props.info.id)}> </i>
                    </div>
                </td>
            </tr>
        )
    }
}