import React from 'react'
import BaseItem from '../BaseItem'

export default class OrgItem extends BaseItem {
    
    render() {
        return (
            <tr onClick={this.onClick.bind(this, this.props.info.ID)}
                style={{ cursor: 'pointer' }}>
                <th title={this.props.info.ID}>
                    {this.props.info.TITLE}
                </th>

                <td title={this.props.info.ADRESS_FACK?.TITLE}>
                    <small>
                        {this.props.info.ADRESS_FACK?.TITLE}
                    </small>
                </td>

                <td>
                    <div>
                        <i className="fas fa-fw fa-clipboard" title="Скопировать GUID"
                            onClick={this.copyCB.bind(this, this.props.info.ID)}> </i>
                    </div>
                </td>
            </tr>
        )
    }
}