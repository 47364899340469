import React from 'react'

export default function Modem(props) {
    const item = props.item

    return (
        <span
            onClick={props.onClick ?? null}
            style={props.onClick ? { cursor: 'pointer' } : null}
            title={props.onClick ? 'Просмотреть' : null}>
            {/*<i className="s-icon icon-bs"></i> */}{item.SERNUMBER}
        </span>
    )
}