import React from 'react'
import * as ReactDOMClient from 'react-dom/client';
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'

const container = document.getElementById('root');

const app = (
    <BrowserRouter>
        <App />
    </BrowserRouter>
)

const root = ReactDOMClient.createRoot(container);
root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
