import React from 'react'

export default function LeakageSensor(props) {
    const item = props.item

    return (
        <span
            onClick={props.onClick ?? null}
            style={props.onClick ? { cursor: 'pointer' } : null}
            title={props.onClick ? 'Просмотреть' : null}>
            {item.SERIAL_NUMBER} {item.MODEL?.TITLE ? `(${item.MODEL?.TITLE})` : null}
        </span>
    )
}