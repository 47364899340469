import React from 'react'
import BaseItem from '../BaseItem'

export default class AbonentItem extends BaseItem {
    web_role = role_id => Object.keys(this.props.webRoles).find(key => this.props.webRoles[key] === role_id)

    render() {
        return (
            <tr onClick={this.onClick.bind(this, this.props.info.ID)}
                style={this.props.info.ACCOUNT_EXPIRED ? { color: 'gray', cursor: 'pointer' } : { cursor: 'pointer' }}>
                <td title={this.props.info.ID}>
                    {this.props.info.LOGIN}
                    <div>
                        <small>{this.props.info.EMAIL}</small>
                    </div>
                </td>

                <td>
                    {this.props.info.WEB_ROLE_TITLE}
                </td>

                <td>
                    {this.props.info.CLIENT_TITLE}
                </td>

                <td>
                    {this.props.info.LIC_SCHET_NUMBER}
                </td>

                <td>
                    {this.props.info.ORG_TITLE}
                </td>

                <td>
                    {this.props.info.ADDRESS_TITLE}
                </td>

                <td>
                    <div>
                        <i className="fas fa-fw fa-clipboard" title="Скопировать GUID"
                            onClick={this.copyCB.bind(this, this.props.info.ID)}> </i>

                        {this.props.info.COMMENT ?
                            <i className="fas fa-fw fa-comment-dots" title={this.props.info.COMMENT}> </i> :
                            <i className="fas fa-fw"> </i>}
                        {this.props.info.ACCOUNT_EXPIRED ?
                            <i className="fas fa-fw fa-lock text-danger" title="Неактивный"> </i> :
                            <i className="fas fa-fw"> </i>}
                    </div>
                </td>

            </tr>
        )
    }
}