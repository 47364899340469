import BaseForm from '../BaseForm'

import React from 'react'
import { Modal, Button, Form, Row } from 'react-bootstrap'
import SearchInputAddr from '../../../Components/General/SearchInputs/searchInputAddr'
import SearchInputObj from '../../../Components/General/SearchInputs/searchInputObj'

import FormControlsRender from '../../../Utils/FormControlsRender'
import { Text } from '../../../Utils/FormControlsPresets'
import ConfirmModal from '../../../Components/General/ConfirmModal'

export default class OrgForm extends BaseForm {
    constructor(props) {
        super(props)

        this.state = {
            requestProcess: false,
            card: {},
            formControls: {
                TITLE: Text('Наименование'),
                CODE: Text('Код'),
                INN: Text('ИНН'),
                KPP: Text('КПП'),
                OGRN: Text('ОГРН'),
                NUMBERSCHET: Text('Номер счёта'),
                AUTO_NUMBER_LS: Text('Счетчик для автоматической генерации ЛС'),
                RASP: Text('Режим работы'),
            },
            postData: {}
        }


        this.id = props.id //для обновления ID
    }

    input = event => {
        let card = this.state.card

        if (event.target.name === 'DATE_PROVER' || event.target.name === 'MEJPR_GOST') {
            var nDATE_PROVER = this.state.card.DATE_PROVER
            var nMEJPR_GOST = this.state.card.MEJPR_GOST
            if (event.target.name === 'DATE_PROVER') nDATE_PROVER = event.target.value
            if (event.target.name === 'MEJPR_GOST') nMEJPR_GOST = event.target.value
            if ((parseInt(nMEJPR_GOST) > 0) && (nDATE_PROVER)) {
                var Dy = new Date(nDATE_PROVER)
                Dy.setFullYear(Dy.getFullYear() + parseInt(nMEJPR_GOST))
                card['DATE_PROVER_NEXT'] = Dy
            }
        }
        card[event.target.name] = event.target.value
        if (event.target.value === '') card[event.target.name] = null
        this.setState({
            card,
            postData: Object.assign({}, this.state.postData, { [event.target.name]: event.target.value })
        })
    }

    render() {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{(this.state.card.ID ? 'Редактирование' : 'Создание') + ' организации'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            {FormControlsRender('TITLE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
                            {FormControlsRender('CODE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4', { disabled: true })}
                            {FormControlsRender('INN', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
                        </Row>
                        <Row>
                            {FormControlsRender('KPP', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
                            {FormControlsRender('OGRN', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
                            {FormControlsRender('NUMBERSCHET', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
                        </Row>
                        <Row>
                            {FormControlsRender('AUTO_NUMBER_LS', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}

                            <SearchInputObj
                                label="Банк"
                                source="bank"
                                server={this.props.server}
                                onSelectItem={item => {
                                    this.setState({
                                        postData: Object.assign({}, this.state.postData, { BANK: item.ID ?? null })
                                    })
                                }}
                                defaultItem={{
                                    ID: this.state.card.BANK,
                                    TITLE: this.state.card.BANK_TITLE
                                }}
                            />

                            <SearchInputObj
                                label="Руководитель"
                                source="resource"
                                server={this.props.server}
                                onSelectItem={item => {
                                    this.setState({
                                        postData: Object.assign({}, this.state.postData, { RUK_RESOURCE: item.ID ?? null })
                                    })
                                }}
                                defaultItem={{
                                    ID: this.state.card.RUK_RESOURCE,
                                    TITLE: this.state.card.RUK_RESOURCE_TITLE
                                }}
                            />
                        </Row>
                        <Row>
                            <SearchInputObj
                                label="Главный бухгалтер"
                                source="resource"
                                server={this.props.server}
                                onSelectItem={item => {
                                    this.setState({
                                        postData: Object.assign({}, this.state.postData, { BUH_RESOURCE: item.ID ?? null })
                                    })
                                }}
                                defaultItem={{
                                    ID: this.state.card.BUH_RESOURCE,
                                    TITLE: this.state.card.BUH_RESOURCE_TITLE
                                }}
                            />

                            {FormControlsRender('RASP', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}

                        </Row>

                        <Row>
                            <SearchInputAddr
                                key={this.state.card?.ADRESS_YOUR?.ID ?? 'address'}
                                size='12'
                                server={this.props.server}
                                closeSearch={this.closeSearchAddr}
                                defaultItem={this.state.card?.ADRESS_YOUR ?? {}}
                                onSelectItem={item => {
                                    this.setState({
                                        postData: Object.assign({}, this.state.postData, { ADRESS_YOUR: item.ID ?? null })
                                    })
                                }}
                                addAddr={this.addAddr}
                            />
                        </Row>

                        <Row>
                            <SearchInputAddr
                                key={this.state.card?.ADRESS_YOUR?.ID ?? 'address'}
                                size='12'
                                server={this.props.server}
                                closeSearch={this.closeSearchAddr}
                                defaultItem={this.state.card?.ADRESS_FACK ?? {}}
                                onSelectItem={item => {
                                    this.setState({
                                        postData: Object.assign({}, this.state.postData, { ADRESS_FACK: item.ID ?? null })
                                    })
                                }}
                                addAddr={this.addAddr}
                            />
                        </Row>

                        <div style={{ height: '15px' }}></div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {this.id ? <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button> : null}
                    <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
                    <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
                    <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.cancelClick}>Отмена</Button>
                </Modal.Footer>

                <ConfirmModal {...this.state.confirmModal}/>
            </>
        )
    }
}