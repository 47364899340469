import React, { Component } from 'react'
import { RequestWithToken } from '../Request'
import { Row } from "react-bootstrap"
import { showNotificationErrors } from '../../../Utils/Helpers'

export default class SearchForTab extends Component {

  typingTimer

  state = {
    searchText: '',
    cards: [],
    thisCounter: null,
    addForm: false,
    searchMode: 0,
  }

  search = () => {
    this.load(this.state.searchText)
  }

  componentDidMount() {
    this.search()
  }

  async load(searchText = '') {
    this.setState({ loading: true, errorconnect: false })

    RequestWithToken(this.props.server ? `retranslate/${this.props.server}/${this.props.url}` : this.props.url)
      .get({
        start: 0,
        limit: 20,
        search: searchText,
        searchMode: this.state.searchMode,
        ...(this.props.filter ?? {})
      })
      .then(data => this.setState({
        cards: data.items,
        totalCount: data.total_count,
        count: data.count,
        loading: false,
      }))
      .catch(e => {
        showNotificationErrors('Ошибка получения данных', e.response ?? null)
        this.setState({ errorconnect: true, totalCount: 0, count: 0, cards: [] })
      })
  }

  clearSearch = () => {
    this.setState({
      searchText: '',
      cards: [],
    })
    this.props.closeSearch()
  }

  clearList = () => {
    this.setState({ cards: [] })
  }

  SearchKeyUp = event => {
    if (event.keyCode === 13) {
      this.search()
    }
  }

  SearchInput = event => {
    let searchText = event.target.value

    this.setState({ searchText })

    clearTimeout(this.typingTimer)
    this.typingTimer = setTimeout(() => this.search(searchText), 500)
  }

  render() {
    return (
      <div>
        <Row>
          <div className="col-md-12">
            <div className="input-group mb-3">
              <input placeholder="Поиск" type="text" autoFocus={true} className="form-control" value={this.state.searchText}
                onChange={this.SearchInput} onKeyUp={this.SearchKeyUp} />
              <div className="input-group-append">
                {this.props.directory ?
                  <a href={`/server/${this.props.server}/${this.props.directory}`} target="_blank" rel="noopener noreferrer" className="btn btn-default" type="button" onClick={this.openList}
                    title="Открыть справочник в новой вкладке">
                    <i className="fas fa-fw fa-list"> </i>
                  </a> : null}
                <button className="btn btn-default" type="button" onClick={this.clearSearch} title="Закрыть поиск">
                  <i className="fas fa-fw fa-times"> </i>
                </button>
              </div>
            </div>
            <div className="zoneSearchInputRes">
              {this.state.cards.map(item => {
                return this.props.renderFunction(item)
              })}
              {this.state.cards.length > 0 ? null :
                <div style={{ textAlign: 'center' }}>Ничего не найдено
                  <div>
                    {this.props.newClick ? <button className="btn btn-default" onClick={() => this.props.newClick(this.state.searchText)}>Cоздать новый</button> : null}
                  </div>
                </div>
              }
            </div>
          </div>
        </Row>
      </div>
    )
  }
}