import React from 'react'
import '../../menu.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import {NavLink} from "react-router-dom"

export default class MenuItem extends React.Component {

  render() {
    return (
        <NavLink to={this.props.to} className="item" title={this.props.title ?? this.props.name}>
            <span className={'fas fa-fw ' + this.props.icon}></span> {this.props.name}
        </NavLink>
    )
  }
}