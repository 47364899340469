import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import styles from './Map.module.css'

export default class Map extends React.Component {
  state = {
    coordinates: [null, null]
  }

  loadScript = (src, onLoad) => {
    if (window.ymaps !== undefined) {
      onLoad()
      return
    }

    const script = document.createElement("script")
    script.id = 'yamaps'
    script.src = src
    script.async = true
    document.body.appendChild(script)
    script.onload = onLoad
    script.onerror = () => {
      toast.error('Ошибка yandex maps')
      this.props.hideCallback()
    }
  }

  init = () => {
    let params = {
      center: [59.496057, 57.631377],
      zoom: 4
    }

    if (this.props.coordinates[0] !== null && this.props.coordinates[1] !== null) {
      params = {
        center: this.props.coordinates,
        zoom: 17
      }
    }

    this.setState({ coordinates: params.center })

    params.controls = ['typeSelector', 'zoomControl', 'searchControl', 'rulerControl']

    this.myMap = new window.ymaps.Map("map", params)
    this.createLine()
  }

  createLine = () => {
    this.myMap.events.add('actionend', e => {
      const center = this.myMap.getCenter()
      const lat = Math.round(center[0] * 1000000) / 1000000
      const long = Math.round(center[1] * 1000000) / 1000000
      this.setState({ coordinates: [lat, long] })
    })
  }

  componentDidMount() {
    this.loadScript(`//api-maps.yandex.ru/2.1/?apikey=${sessionStorage.YANDEX_MAPS_API_KEY}&lang=ru_RU`, () => {
      window.ymaps.ready(this.init)
    })
  }

  componentWillUnmount() {
    if (this.myMap !== undefined) {
      this.myMap.destroy()
    }
    
    var elem = document.getElementById('yamaps')
    if (elem) {
      elem.parentNode.removeChild(elem)
    }
  }

  cancelClick = e => {
    this.props.hideCallback()
  }

  saveClick = e => {
    this.props.hideCallback(this.state.coordinates)
  }

  render() {
    return <>
      <Modal.Header closeButton>
        <Modal.Title>Координаты точки</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ position: 'relative' }}>
          <div id="map" style={{ width: '100%', height: 600 }} />
          <div className={styles.crossControl}></div>
        </div>
        <div>{this.state.coordinates.join(',')}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.saveClick}>Применить</Button>
        <Button variant="secondary" onClick={this.cancelClick}>Отмена</Button>
      </Modal.Footer>
    </>
  }
}