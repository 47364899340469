import React, { useState, useEffect, useRef } from 'react'
import { RequestWithToken } from '../Request'
import { getObjHashCode } from '../../../Utils/Helpers'
import styles from './searchInputAddr.module.css'

import conf from '../../../Config'
import { toast } from 'react-toastify'
import { Button } from 'react-bootstrap'

import { MODE_SMART } from './searchInputObj'

export default function SearchInputAddr(props) {
  const searchMode = MODE_SMART

  const [searchText, setSearchText] = useState(props.defaultItem?.TITLE ?? '')
  const [item, setItem] = useState(props.defaultItem ?? {})
  const [items, setItems] = useState([])
  const [itemsFias, setItemsFias] = useState([])
  const [searchAddr, setSearchAddr] = useState(false)
  const [showAddButton, setShowAddButton] = useState(false)

  const isNotDebouncingLoad = useRef(true)

  useEffect(() => {
    setItem(props?.defaultItem ?? {})
    setSearchText(props.defaultItem?.TITLE ?? '')
    isNotDebouncingLoad.current = true
    // eslint-disable-next-line
  }, [getObjHashCode(props.defaultItem ?? {})])

  const load = async (search = null) => {
    if (conf.fias) {
      loadFIAS(searchText)
    }

    RequestWithToken(`retranslate/${props.server}/address`)
      .get({
        start: 0,
        limit: 500,
        needTotalCount: 0,
        search: search ?? searchText,
        searchMode
      })
      .then(data => {
        setItems(data.items)
        setShowAddButton(data.items.length ? false : true)
      })
      .catch(() => setItems([]))
      .finally(() => isNotDebouncingLoad.current = true)
  }

  const loadFIAS = async (searchText = '') => {
    RequestWithToken('fias/get-address')
      .get({
        q: searchText,
        limit: 500,
      })
      .then(data => setItemsFias(data?.items ?? []))
      .catch(() => setItemsFias([]))
  }

  const saveAddr = async (pred) => {
    RequestWithToken(`retranslate/${props.server}/address`)
      .post({ "TITLE": pred })
      .then(data => {
        if (data.ID) {
          onSelectItem({ ID: data.ID, TITLE: pred })
          toast.success('Адрес создан')
        }
      })
      .catch(e => {
        toast.error('Ошибка сохранения:')
        for (var err_v in e.response?.data?.errors ?? []) {
          toast.warning(e.response.data.errors[err_v])
        }
      })
  }

  const searchKeyUp = event => {
    if (event.keyCode === 13) {
      load(searchText)
    }
  }

  const searchInput = e => setSearchText(e.target.value.trim())
  useEffect(() => {
    if (isNotDebouncingLoad.current) {
      isNotDebouncingLoad.current = false
      return
    }

    const timer = setTimeout(() => {
      load(searchText)
    }, 500)

    return () => clearTimeout(timer)
    // eslint-disable-next-line
  }, [searchText])

  const addAddrFias = (addrTitle) => saveAddr(addrTitle)

  const addAddr = () => saveAddr(searchText)

  const addAddrBt = () => {
    setSearchAddr(true)
    load(item?.TITLE ?? '')
  }

  const remAddrBt = () => onSelectItem({ ID: null, TITLE: '' })

  const onSelectItem = item => {
    setSearchAddr(false)
    setSearchText(item.TITLE)
    setItem(item)
    props.onSelectItem(item)
  }

  return (
    <div className={`${styles.searchInputAddr} form-group col-md-${props.size ?? 12}`}>
      <label className={styles.searchInputAddrLabel}>
        {props.label ?? 'Адрес'} <span className="text-muted">(формат: Ростовская обл, Ростов-на-Дону г, Грациозная ул, д. 12, кор. 105)</span></label>
      <div className={`${styles.dropdown} dropdown`}>
        <Button variant="default" className={`${styles.addButton} noRoundR`}
          onClick={addAddrBt}>{item?.TITLE} &nbsp;</Button>
      </div>

      {searchAddr ?
        <>
          <div className={styles.searchInputAddrSearch}>
            <div className="input-group mb-3">
              <input type="text" placeholder="Поиск адреса" autoFocus={true} className="form-control" value={searchText}
                onChange={searchInput} onKeyUp={searchKeyUp} />
              <div className="input-group-append">
                {showAddButton ?
                  <button className="btn btn-warning hide" type="button" onClick={addAddr} title="Сохранить введённый адрес">
                    <i className="fas fa-fw fa-plus-square"> </i>
                  </button> : null}
                <button className="btn btn-default" type="button" onClick={remAddrBt} title="Очистить">
                  <i className="fas fa-fw fa-times"> </i>
                </button>
              </div>
            </div>
            <div className={styles.searchInputAddrRes}>
              {items.map((item, idx) => {
                return (
                  <div key={'s' + idx}>
                    <i className="fas fa-fw fa-copy pointer" title="Скопировать адрес для редактирования" onClick={() => {
                      setSearchText(item.TITLE)
                      load(item.TITLE)
                    }}> </i>&nbsp;&nbsp;
                    <span className="pointer" title="Привязать" onClick={() => onSelectItem(item)}>{item.TITLE}</span>
                  </div>
                )
              })}
              {(items.length > 0 || searchText.length < 1) ? null :
                <div className={styles.textCenter}>В справочнике адресов ничего не найдено</div>}

              {conf.fias ? <div className={styles.textCenter}>Поиск в ФИАС:</div> : null}
              {((!conf.fias) || (itemsFias.length > 0) || (searchText.length < 1)) ? null :
                <div className={styles.textCenter}>В справочнике ФИАС ничего не найдено</div>}

              {itemsFias.map((addr, idx) => {
                return (
                  <div key={'f' + idx}>
                    <i className="fas fa-fw fa-copy pointer" title="Скопировать адрес для редактирования" onClick={() => {
                      setSearchText(addr.title)
                      load(addr.title)
                    }}> </i>&nbsp;&nbsp;
                    <span className="pointer" title="Привязать" onClick={() => addAddrFias(addr.title)}>{addr.title}</span>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={styles.transparentBlock}
            onClick={() => {
              setSearchAddr(false)
              setSearchText(item?.TITLE ?? '')
            }}
          ></div>
        </>
        : null}
    </div>
  )
}