import Base from '../inServer/Base'

import React from 'react'
import UserItem from "./UserItem"
import UserForm from "./UserForm"
import TableSearch from "../../Components/General/TableSearch/TableSearch"
import { Modal } from 'react-bootstrap'
import Error from '../../Components/General/Alerts/error'
import AddBtn from '../../Components/General/AddBtn'
import { userCan } from '../../Utils/Helpers'

export default class UserList extends Base {
    mainUrl = 'user'

    render() {
        return (<>
            <h1 className={'d-none d-sm-block'}>Работа с пользователями </h1>
            <div aria-label="breadcrumb" className="navInSrv">
                <ol className="breadcrumb"></ol>
            </div>
            <div className="inmodule">
                <TableSearch searchParams={this.searchParams} setParentCallback={this.setCallback} />

                <div className="centerCount">Показано {this.state.cards.length} из {this.state.totalCount}</div>

                <table className="table table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" style={{ width: '500px' }}>ФИО</th>
                            <th scope="col" style={{ width: '250px' }}>Логин</th>
                            <th scope="col" style={{ width: 'calc(100vw - 850px)' }}>Email</th>
                            <th scope="col" style={{ width: '150px' }}>Параметры</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.cards.map(item => {
                            return (
                                <UserItem key={item.id} info={item} onClick={this.showModal} />
                            )
                        })}
                    </tbody>
                </table>

                {this.state.cards.length < this.state.totalCount ? this.getMoreButton() : null}

                <div style={{ height: '60px' }}></div>

                {userCan(this.mainUrl, 'create') ?
                    <AddBtn onClick={this.newClick()} />
                    : null}

                <Modal show={this.state.modalShow} onHide={this.hideEditModal} size="lg">
                    <UserForm
                        id={this.state.modalItemId}
                        hideCallback={this.hideEditModal}
                        parentReloadCallback={this.reloadCallback}
                        mainUrl={this.mainUrl} />
                </Modal>

                {(this.state.errorconnect) ? <Error /> : null}
            </div>
        </>
        )
    }
}