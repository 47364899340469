import BaseForm from '../BaseForm'

import React from 'react'
import { Modal, Button, Form, Nav, Row } from 'react-bootstrap'
import SearchInputObj from '../../../Components/General/SearchInputs/searchInputObj'
import Tab from '../../../Components/General/Tab'

import FormControlsRender from '../../../Utils/FormControlsRender'
import { Text } from '../../../Utils/FormControlsPresets'
import ConfirmModal from '../../../Components/General/ConfirmModal'

import CounterForm from '../counters/CounterForm'
import Counter from '../../../Components/Devices/Counter'

export default class BalanceForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      navKey: '1',
      requestProcess: false,
      card: {},
      formControls: {
        TITLE: Text('Название балансовой группы'),
        TYPE_COUNTERS: {}
      },
      postData: {},
      counterModalShow: false,
      thisCounter: null
    }

    this.id = props.id //для обновления ID
  }

  clickCounterOpen = (id = null) => {
    this.setState({ thisCounter: id, counterModalShow: true })
  }

  hideCounterModal = () => {
    this.setState({ thisCounter: null, counterModalShow: false })
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{(this.state.card.ID ? 'Редактирование' : 'Создание') + ' балансовой группы'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              {FormControlsRender('TITLE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-8')}

              <SearchInputObj
                label="Вид ресурса"
                source="type-counter"
                server={this.props.server}
                render={item =>
                  <><span className={`fas res16 res-${item.ICON_NAME}`}> </span>{item.RES_NAME}</>
                }
                onSelectItem={item => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, { TYPE_COUNTERS: item.ID ?? null })
                  })
                }}
                defaultItem={{
                  ID: this.state.card.TYPE_COUNTERS,
                  RES_NAME: this.state.card.RES_NAME,
                  ICON_NAME: this.state.card.ICON_NAME
                }}
                errorMessage={this.state.formControls.TYPE_COUNTERS?.errorMessage}
              />

            </Row>

            <div style={{ height: '15px' }}></div>

            <div style={{ height: '15px' }}></div>

            <Nav variant="tabs" defaultActiveKey={this.state.navKey} onSelect={key => this.setState({ navKey: key })}>
              <Nav.Item>
                <Nav.Link eventKey="1">Балансовые ПУ</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="2">ПУ</Nav.Link>
              </Nav.Item>
            </Nav>

            <div className="tab-content tabContent" id="nav-tabContent">
              <span style={{ display: this.state.navKey !== '1' ? 'none' : null }}>
                <Tab
                  cards={this.state.card?.BALANCE ?? []}
                  onChangeHandler={() => this.loadAndParentReload(this.id, ['BALANCE'])}
                  renderFunction={item => <Counter item={item} onClick={this.clickCounterOpen.bind(this, item.ID)} />}
                  renderSearchFunction={item => <Counter item={item} />}
                  addUrl={`retranslate/${this.props.server}/balance-balance`}
                  addDataIn={item => ({ OWNER: this.id, COUNTER: item.ID })}
                  deleteUrl={item => (`retranslate/${this.props.server}/balance-balance/${item.ID_LINK}`)}
                  searchUrl={`retranslate/${this.props.server}/counter`}
                  filter={{
                    noBalBal: this.id ?? null,
                    type: this.state.card.TYPE_COUNTERS ?? null,
                    isBalance: 1
                  }}
                  disabled={!this.id}
                />
              </span>
              <span style={{ display: this.state.navKey !== '2' ? 'none' : null }}>
                <Tab
                  cards={this.state.card?.COUNTERS ?? []}
                  onChangeHandler={() => this.loadAndParentReload(this.id, ['COUNTERS'])}
                  renderFunction={item => <Counter item={item} onClick={this.clickCounterOpen.bind(this, item.ID)} />}
                  renderSearchFunction={item => <Counter item={item} />}
                  addUrl={`retranslate/${this.props.server}/balance-counter`}
                  addDataIn={item => ({ OWNER: this.id, COUNTER: item.ID })}
                  deleteUrl={item => (`retranslate/${this.props.server}/balance-counter/${item.ID_LINK}`)}
                  searchUrl={`retranslate/${this.props.server}/counter`}
                  filter={{
                    noBal: this.id ?? null,
                    type: this.state.card.TYPE_COUNTERS ?? null,
                    isBalance: 0
                  }}
                  disabled={!this.id}
                />
              </span>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {this.id ? <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button> : null}
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
          <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.cancelClick}>Отмена</Button>
        </Modal.Footer>

        <Modal show={this.state.counterModalShow} onHide={this.hideCounterModal} size="xl">
          <CounterForm
            server={this.props.server}
            id={this.state.thisCounter}
            hideCallback={this.hideCounterModal}
            parentReloadCallback={() => this.loadAndParentReload(this.id, ['COUNTERS', 'BALANCE'])}
            mainUrl='counter' />
        </Modal>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    )
  }
}