import BaseItem from '../inServer/BaseItem'

import React from 'react'

export default class UserItem extends BaseItem {

    render() {
        return (
            <tr onClick={this.onClick.bind(this, this.props.info.id)} style={{ cursor: 'pointer' }}>
                <th>
                    {this.props.info.status === 9 ? <i className="fas fa-fw fa-lock text-danger"></i> : null}
                    {this.props.info.status === 10 ? <i className="fas fa-fw fa-lock-open"></i> : null}
                    &nbsp;{this.props.info.title}
                </th>

                <td>
                    {this.props.info.username}
                </td>

                <td>
                    {this.props.info.email}
                </td>
                <td>
                    <div>
                        <i className="fas fa-fw fa-clipboard" title="Скопировать GUID"
                            onClick={this.copyCB.bind(this, this.props.info.id)}> </i>
                    </div>
                </td>
            </tr>
        )
    }
}