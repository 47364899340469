import BaseForm from '../inServer/BaseForm'

import React from 'react'
import { Button, Form, Modal, Row } from 'react-bootstrap'
import ConfirmModal from '../../Components/General/ConfirmModal'

import FormControlsRender from '../../Utils/FormControlsRender'
import { Text, Numeric, Password } from '../../Utils/FormControlsPresets'
import Guid from '../../Components/Guid'
import Tab from '../../Components/General/Tab'
import { userCan, userCanOr } from '../../Utils/Helpers'

export default class ServerForm extends BaseForm {
    idField = 'id' //в weather он например CITY_ID

    constructor(props) {
        super(props)

        this.state = {
            requestProcess: false,
            formControls: {
                code: Numeric('Код', 0, 999999, /^\d{0,6}$/),
                api_ver: Numeric('Версия API', 1, 99, /^\d{0,2}$/),
                token_status: Numeric('Ошибка', 1, 99, /^\d{0,2}$/),
                name: Text('Наименование'),
                kpo_url: Text('Адрес КПО'),
                spo_url: Text('Адрес СПО'),
                url: Text('Адрес API'),
                user: Text('Пользователь'),
                password: Password('Пароль', 'заполните поле, если необходимо сменить пароль')
            },
            card: {},
            postData: {}
        }

        this.id = props.id //для обновления ID
    }

    getDateIn() {
        return this.state.postData
    }

    render() {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{`${this.id ? 'Редактирование' : 'Создание'} сервера`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={userCanOr(this.props.mainUrl, ['update', 'create']) ? null : "disabled"}>
                    <Form>
                        <Row>
                            <Guid key={this.id} value={this.id} />
                        </Row>
                        <Row>
                            {FormControlsRender('name', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-10')}
                            {FormControlsRender('code', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-2')}
                        </Row>
                        <Row>
                            {FormControlsRender('url', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-8')}
                            {FormControlsRender('api_ver', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-2')}
                            {FormControlsRender('token_status', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-2')}
                        </Row>
                        <Row>
                            {FormControlsRender('user', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-6')}
                            {FormControlsRender('password', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-6')}
                        </Row>
                        <Row>
                            {FormControlsRender('kpo_url', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-6', { disabled: true })}
                            {FormControlsRender('spo_url', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-6', { disabled: true })}
                        </Row>
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <a className="nav-item nav-link active" id="modules-tab" data-toggle="tab" href="#modules-tab" role="tab" aria-controls="modules-tab"
                                    aria-selected="true">Модули</a>
                            </div>
                        </nav>
                        <div className="tab-content tabContent" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="modules-tab" role="tabpanel" aria-labelledby="nav-home-pu">
                                {<Tab
                                    cards={this.state.card?.modules ?? []}
                                    onChangeHandler={() => this.loadAndParentReload(this.id, ['modules'])}
                                    renderFunction={item => item.name}
                                    renderSearchFunction={item => item.name}
                                    addUrl={`server-module`}
                                    addDataIn={item => ({ server: this.id, module: item.id })}
                                    deleteUrl={item => (`server-module/${item.id_link}`)}
                                    searchUrl={`module`}
                                    filter={{ noServer: this.id ?? null }}
                                    disabled={!this.id}
                                />}
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {this.id && userCan('server', 'delete') ?
                        <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button>
                        : null}
                    {userCan(this.props.mainUrl, this.id ? 'update' : 'create') ?
                        <>
                            <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
                            <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
                        </>
                        : null}
                    <Button disabled={this.state.requestProcess} variant="secondary" data-dismiss="modal" onClick={this.props.hideCallback}>Отмена</Button>
                </Modal.Footer>

                <ConfirmModal {...this.state.confirmModal} />
            </>
        )
    }
}