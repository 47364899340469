import React from 'react'
import { Alert, Form, Button, Card, Row, Col } from 'react-bootstrap'
import '../../login.css'
import conf from '../../Config'
import { Request } from '../../Components/General/Request'

export default class LoginForm extends React.Component {
  state = {
    loginform: {
      username: '',
      password: '',
    },
    error: null,
    success: false,
    redirect: false,
  }

  passRef = React.createRef()

  componentDidMount() {
    this.passNode = this.passRef.current
  }

  onLoginInput = () => event => {
    this.setState({
      loginform: { username: event.target.value, password: this.state.loginform.password },
      error: null
    })
  }

  onPasswordInput = () => event => {
    this.setState({
      loginform: { password: event.target.value, username: this.state.loginform.username },
      error: null
    })
  }

  login = async event => {
    this.setState({ success: false, error: null })
    const button = event.target
    button.innerHTML = 'авторизация...'
    button.disabled = true

    Request('auth')
      .post(this.state.loginform)
      .then(data => {
        this.setState({ success: true, error: null, redirect: true })
        localStorage.token = data.token
        localStorage.title = data.title
        localStorage.id = data.id
        localStorage.permissions = JSON.stringify(data.permissions)
        this.props.setLoginStatus(true)
      })
      .catch(e => {
        const error = e.response?.data?.message ?? null
        this.setState({ success: false, error, redirect: false })
        this.clearAuth()
      })
      .finally(() => {
        button.removeAttribute("disabled")
        button.innerHTML = 'Вход'
      })
  }

  clearAuth() {
    localStorage.token = ''
    localStorage.title = ''
    localStorage.id = ''
    this.props.setLoginStatus(false)
  }

  formSubmit = event => {
    this.login(event)
    event.preventDefault()
  }

  hidePass = () => {
    this.passNode.type = 'password'
  }

  showPass = () => {
    this.passNode.type = 'text'
  }

  keyUp = event => {
    if (event.keyCode === 13) {
      this.formSubmit(event)
    }
  }

  render() {
    return (
      <>
        <div className="login-bg">
          <div className="Login">
            <Card className="login-card">
              <Card.Body>
                <Row>
                  <Col className="flex">
                    <img src={conf.logo} className="App-logo" alt="logo" />
                  </Col>
                  <Col>
                    <div className="left login-form">
                      <form onSubmit={this.formSubmit}>
                        <Form.Group>
                          <label htmlFor="login">Логин</label>
                          <Form.Control type="text" id="login" name="username"
                            placeholder="Логин"
                            value={this.state.loginform.username}
                            onChange={this.onLoginInput()}
                            onKeyUp={this.keyUp} />
                        </Form.Group>

                        <label htmlFor="password">Пароль</label>
                        <div className="input-group">
                          <Form.Control ref={this.passRef} type="password" id="password" name="password"
                            placeholder="Пароль"
                            value={this.state.loginform.password}
                            onChange={this.onPasswordInput()}
                            onKeyUp={this.keyUp} />
                          <span className="input-group-btn"><button className="btn btn-default" style={{ height: '100%', outline: 'none' }} type="button"
                            onMouseUp={this.hidePass} onMouseDown={this.showPass} title="Просмотреть пароль"><span className="fa fa-eye"></span></button></span>
                        </div>

                        <Form.Group>
                          <Form.Check  type='checkbox' name='savelogin' label='Запомнить меня' />
                        </Form.Group>
                        <div className="right">
                          <Button variant='dark' className="per100" onClick={this.login}>Вход</Button>
                        </div>
                      </form>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {(this.state.error) ?
              <Alert variant={'danger'} className="animated tada">{this.state.error}</Alert> : null}
            {(this.state.success) ?
              <Alert variant={'success'} className="animated tada">Успешная авторизация. Загрузка...</Alert> : null}
          </div>
        </div>
      </>
    )
  }
}