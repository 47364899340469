import React from 'react'

export default function Guid(props) {
    return (
        <div className="input-group">
            <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">GUID</span>
            </div>
            <input type="text" className="form-control" disabled="disabled" value={props.value ?? ''} />
        </div>
    )
}