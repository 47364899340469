import React, { Component } from 'react'
import MkdCell from "./mkdCell"

export default class MkdStage extends Component {

  stage_str = (stage) => {
    switch (Number(stage)) {
      case -1000:
        return 'Подвал'
      case 0:
        return 'Цокольный этаж'
      case 1000:
        return 'Технический этаж'
      case 2000:
        return 'Чердак'
      case 3000:
        return 'Крыша'
      default:
        return 'Этаж ' + stage
    }
  }

  render() {
    this.cards_list = []
    for (var scheme_x in this.props.stage.IN) {
      this.cards_list.push({
        N: scheme_x,
        IN: this.props.stage.IN[scheme_x],
      })
    }

    return (
      <tr>
        <td onClick={this.props.StageClick} className="mkdStage mkd-poded">{this.stage_str(this.props.stage.N)}</td>
        {this.cards_list.map((cell) => {
          return (
            <MkdCell cell={cell} stage={this.props.stage} CellClick={this.props.CellClick} CounterClick={this.props.CounterClick} BsClick={this.props.BsClick}
              allowDrop={this.props.allowDrop} drag={this.props.drag} dragend={this.props.dragend} drop={this.props.drop}
              searchText={this.props.searchText} DallowDrop={this.props.DallowDrop} key={cell.N} />
          )
        })}
      </tr>
    )
  }
}