import React from 'react'
import axios from 'axios'
import { RequestWithToken, CANCEL } from '../../Components/General/Request'
import { showNotificationErrors } from '../../Utils/Helpers'

export default class Base extends React.Component {

    signal = axios.CancelToken.source()

    state = {
        errorconnect: false,
        cards: [],
        totalCount: 0,
        modalItemId: null,
        modalShow: false,
    }

    searchParams = {
        limit: 100,
        needTotalCount: 1,
        searchMode: 0,
        handleLoad: (searchText = '', searchMode = 0, replace = true, filter = {}) => {
            this.load(searchText, searchMode, replace, filter)
        }
    }

    /**
     * Здесь будет функция подгрузки данных из TableSearch
     */
    reloadCallback = () => { }
    /**
     * Здесь будет функция установки фильтра TableSearch
     */
    setFilter = () => { }

    /**
     * Определит функцию подгрузки данных (передаем в TableSearch)
     */
    setCallback = (name, handler) => {
        this[name] = handler
    };

    loadMore = () => {
        this.reloadCallback(false)
    }

    async load(searchText = '', searchMode = 0, replace = true, filter = {}) {
        this.setState({ errorconnect: false })

        const server = this.props?.match?.params?.server ?? this.props.server

        RequestWithToken(server ? `retranslate/${server}/${this.mainUrl}` : this.mainUrl)
            .setCancelToken(this.signal.token)
            .get({
                start: replace ? 0 : this.state.cards.length,
                limit: this.searchParams.limit,
                needTotalCount: this.searchParams.needTotalCount,
                search: (searchText ?? '').trim(),
                searchMode: searchMode,
                ...filter
            })
            .then(data => {
                const cards = data.items ?? []
                const totalCount = data.total_count ?? 0
                this.setState({
                    cards: (replace ? cards : this.state.cards.concat(cards)),
                    totalCount
                })
            })
            .catch(e => showNotificationErrors('Ошибка получения данных', e.response ?? null))
            .finally()
    }

    getNotificationErrors(errors) {
        let notifErrors = []

        if (typeof errors === 'string') {
            notifErrors.push(errors)
        } else if (Array.isArray(errors) || (typeof errors === 'object')) {
            for (const [key, value] of Object.entries(errors)) {
                if (!this.state.formControls[key]) {
                    notifErrors.push(...value)
                }
            }
        }

        return notifErrors
    }

    hideEditModal = () => {
        this.setState({
            modalItemId: null,
            modalShow: false
        })
    }

    newClick = () => () => {
        this.setState({
            modalItemId: null,
            modalShow: true
        })
    }

    showModal = id => this.setState({
        modalItemId: id,
        modalShow: true
    })

    getMoreCount = () => {
        if (this.state.totalCount > (this.state.cards.length + this.searchParams.limit)) {
            return this.searchParams.limit
        } else {
            return this.state.totalCount - this.state.cards.length
        }
    }

    getMoreButton = () => {
        return (
            <div style={{ textAlign: 'center' }}>
                <button type="button" className="btn btn-success1 btn-outline-info"
                    title="Загрузить ещё" onClick={() => this.loadMore()}>смотреть ещё +{this.getMoreCount()}
                </button>
            </div>
        )
    }

    componentDidMount() {
        this.load()
    }

    componentWillUnmount() {
        this.signal.cancel(CANCEL)
    }

}