import React from 'react'

export default class LogOut extends React.Component {

    componentDidMount() {
        this.props.setLoginStatus(false)
        localStorage.token = ''
        localStorage.title = ''
        localStorage.id = ''
    }

    render() {
        window.location.href = '/login'
        return null
    }

}