import React, { useEffect, useState } from "react"
import { Form, ToggleButtonGroup, ToggleButton } from "react-bootstrap"

export default function Permission(props) {
    const name = props.name
    const [permission, setPermission] = useState([])

    useEffect(() => {
        const value = props.permissions?.[name]
        setPermission([value & 1, value & 2, value & 4, value & 8])
    }, [props.permissions, name])

    const onChange = val => {
        setPermission(val)

        const value = val.reduce((a, b) => a + b, 0)

        let permissions = [{ [props.name]: value }]
        const aliases = props.aliases ?? []
        aliases.forEach(alias => {
            permissions.push({ [alias]: value })
        });

        props.onChange(permissions)
    }

    return (
        <Form.Group className={`col-md-${props.size ?? 3} crud`}>
            <Form.Label>{props.label}</Form.Label><br />
            <ToggleButtonGroup type="checkbox" value={permission} onChange={onChange}>
                <ToggleButton id={`perm-${name}-c`} title="Создание" value={2}>С</ToggleButton>
                <ToggleButton id={`perm-${name}-r`} title="Чтение" value={1}>Ч</ToggleButton>
                <ToggleButton id={`perm-${name}-w`} title="Редактирование" value={4}>Р</ToggleButton>
                <ToggleButton id={`perm-${name}-d`} title="Удаление" value={8}>У</ToggleButton>
            </ToggleButtonGroup>
        </Form.Group>
    )
}