import React from 'react'

export default function LS(props) {
    const item = props.item

    return (
        <span
            onClick={props.onClick ?? null}
            style={props.onClick ? { cursor: 'pointer' } : null}
            title={props.onClick ? 'Просмотреть' : null}>
            {item.TITLE}
        </span>
    )
}