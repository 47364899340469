import BaseForm from '../BaseForm'

import React from 'react'
import { Modal, Button, Form, Row } from 'react-bootstrap'
import SearchInputObj from '../../../Components/General/SearchInputs/searchInputObj'

import FormControlsRender from '../../../Utils/FormControlsRender'
import { Numeric, Checkbox } from '../../../Utils/FormControlsPresets'
import ConfirmModal from '../../../Components/General/ConfirmModal'
import Zone from '../../../Components/Zone'

export default class MkdNewForm extends BaseForm {

  constructor(props) {
    super(props)

    this.state = {
      requestProcess: false,
      owner: null,
      formControls: {
        FLOOR_IN: Numeric('Этажи с', -180, 180, /^\-?\d{0,3}(\.?\d{0,6})?$/, '', 1), //eslint-disable-line
        FLOOR_OUT: Numeric('Этажи по', -180, 180, /^\-?\d{0,3}(\.?\d{0,6})?$/, '', 5), //eslint-disable-line
        DOORS: Numeric('Кол-во подъездов', 1, 99, /^\d{1,2}$/, '', 2),
        roof: Checkbox('Крыша'),
        loft: Checkbox('Чердак'),
        technical_floor: Checkbox('Технический этаж'),
        ground_floor: Checkbox('Цокольный этаж'),
        cellar: Checkbox('Подвал'),
      },
      postData: {}
    }
  }

  getDateIn() {
    const formControls = this.state.formControls

    let elements = []
    if (formControls.roof.checked) elements.push(3000)
    if (formControls.loft.checked) elements.push(2000)
    if (formControls.technical_floor.checked) elements.push(1000)
    if (formControls.ground_floor.checked) elements.push(0)
    if (formControls.cellar.checked) elements.push(-1000)
    for (let i = formControls.FLOOR_IN.value; i <= formControls.FLOOR_OUT.value; i++) {
      if (i !== 0) { elements.push(Number(i)) }
    }

    return {
      ...this.state.postData,
      front_doors: formControls.DOORS.value !== '' ? formControls.DOORS.value : null,
      floors: elements,
    }
  }

  afterSave = response => {
    this.setState({ postData: {} })
    if (response.success || response?.status === 'ok') {
      this.props.parentReloadCallback(this.state.owner)
    }
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Создание схемы МКД</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <SearchInputObj
                label="Зона"
                source="zone"
                server={this.props.server}
                size="6"
                render={item => <Zone item={item} />}
                onSelectItem={item => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, { owner: item.ID ?? null }),
                    owner: item.ID ?? null
                  })
                }}
              />

              {FormControlsRender('FLOOR_IN', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-2')}
              {FormControlsRender('FLOOR_OUT', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-2')}
              {FormControlsRender('DOORS', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-2')}

              {FormControlsRender('roof', this.state.formControls, this.onChangeCheckboxHandler, this.onSaveHandler(), 'col-md-12')}
              {FormControlsRender('loft', this.state.formControls, this.onChangeCheckboxHandler, this.onSaveHandler(), 'col-md-12')}
              {FormControlsRender('technical_floor', this.state.formControls, this.onChangeCheckboxHandler, this.onSaveHandler(), 'col-md-12')}
              {FormControlsRender('ground_floor', this.state.formControls, this.onChangeCheckboxHandler, this.onSaveHandler(), 'col-md-12')}
              {FormControlsRender('cellar', this.state.formControls, this.onChangeCheckboxHandler, this.onSaveHandler(), 'col-md-12')}

              <div style={{ "height": '15px' }}>&nbsp;</div>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить</Button>
          <Button variant="secondary" onClick={this.props.hideCallback}>Отмена</Button>
        </Modal.Footer>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    )
  }
}