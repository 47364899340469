import React from 'react'


export default class MkdCounterItem extends React.Component {

  counterIcon = icon => {
    var fa = 'fa-question'
    switch (icon) {
      case 'electro':
        fa = 'fas fa-fw res10 res-electro'
        break
      case 'stok':
        fa = 'fas fa-fw fa-tint-slash'
        break
      case 'w-cold':
        fa = 'fas fa-fw res10 res-w-cold'
        break
      case 'w-hot':
        fa = 'fas fa-fw res10 res-w-hot'
        break
      case 'gaz':
        fa = 'fas fa-fw res10 res-gaz'
        break
      case 'colorimetr':
        fa = 'fas fa-fw res10 res-colorimetr'
        break
      default:
        fa = 'fas fa-fw fa-question'
    }
    return fa
  }

  render() {
    return (
      <span className="badge mkd-counter" id={"c|" + this.props.parent + "|" + this.props.counter.ID + "|" + this.props.counter.ID_LINK} draggable="true" code={this.props.counter.CODE}
        onClick={() => { this.props.CounterClick(this.props.counter.ID) }} title={this.props.counter.COMMENT}
        onDragStart={this.props.onDragStart} onDragEnd={this.props.onDragEnd} onDragOver={this.props.onDragOver}>
        {this.props.counter.IS_BALANCE ? <i className="fas fa-fw fa-balance-scale text-dark" title="Балансовый"> </i> : null}
        <i className={'' + this.counterIcon(this.props.counter.ICON)}> </i>
        {this.props.counter.CODE} {this.props.counter.KVARTIRA ? '(' + this.props.counter.KVARTIRA + ')' : null}</span>
    )
  }
}