import Base from '../inServer/Base'

import React from 'react'
import ServerPermissionItem from "./ServerPermissionItem"
import ServerPermissionForm from "./ServerPermissionForm"
import TableSearch from "../../Components/General/TableSearch/TableSearch"
import { Modal } from 'react-bootstrap'
import Error from '../../Components/General/Alerts/error'
import AddBtn from '../../Components/General/AddBtn'
import { userCan } from '../../Utils/Helpers'
import SearchInputObj from '../../Components/General/SearchInputs/searchInputObj'

export default class ServerPermissionList extends Base {
    mainUrl = 'server-permission'

    filter = <>
        <SearchInputObj
            size={12}
            label="Пользователь"
            source="user"
            onSelectItem={item => this.setFilter('user', item.id)}
            render={item => <>{item.title}</>}
        />
        <SearchInputObj
            size={12}
            label="Сервер"
            source="server"
            onSelectItem={item => this.setFilter('server', item.id)}
            render={item => <>{item.name}</>}
        />
    </>

    render() {
        return (
            <>
                <h1 className={'d-none d-sm-block'}>Работа с разрешениями</h1>
                <div aria-label="breadcrumb" className="navInSrv">
                    <ol className="breadcrumb"></ol>
                </div>
                <div className="inmodule">
                    <TableSearch filter={this.filter} searchParams={this.searchParams} setParentCallback={this.setCallback} />

                    <div className="centerCount">Показано {this.state.cards.length} из {this.state.totalCount}</div>

                    <table className="table table-hover">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Пользователь</th>
                                <th scope="col" style={{ width: '300px' }}>Сервер</th>
                                <th scope="col" style={{ width: '150px' }}>Параметры</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.cards.map(item => {
                                return (
                                    <ServerPermissionItem key={item.id} info={item} onClick={this.showModal} />
                                )
                            })}
                        </tbody>
                    </table>

                    {this.state.cards.length < this.state.totalCount ? this.getMoreButton() : null}

                    <div style={{ height: '60px' }}></div>

                    {userCan('permission', 'create') ?
                        <AddBtn onClick={this.newClick()} />
                        : null}

                    <Modal show={this.state.modalShow} onHide={this.hideEditModal} size="lg" fullscreen="lg-down">
                        <ServerPermissionForm
                            id={this.state.modalItemId}
                            hideCallback={this.hideEditModal}
                            parentReloadCallback={this.reloadCallback}
                            mainUrl={this.mainUrl} />
                    </Modal>

                    {(this.state.errorconnect) ? <Error /> : null}
                </div>
            </>
        )
    }
}