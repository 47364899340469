import Base from '../Base'
import React from 'react'
import MkdItem from "./MkdItem"
import MkdForm from "./MkdForm"
import MkdNewForm from "./MkdNewForm"
import TableSearch from "../../../Components/General/TableSearch/TableSearch"
import { Modal } from 'react-bootstrap'
import Error from '../../../Components/General/Alerts/error'
import AddBtn from '../../../Components/General/AddBtn'

export default class Schemes_mkd extends Base {

    static mainUrl = 'mkd'
    mainUrl = 'mkd'

    state = {
        errorconnect: false,
        cards: [],
        totalCount: 0,
        modalItemId: null,
        modalShow: false,
        showModalNew: false,
    }

    newClick = () => () => {
        this.setState({
            modalItemId: null,
            showModalNew: true
        })
    }

    hideModal = () => () => {
        this.setState({
            showModalNew: false,
            showModalEdit: false
        })
    }

    render() {
        return (
            <div className="inmodule">

                <TableSearch searchParams={this.searchParams} setParentCallback={this.setCallback} />

                <div className="centerCount">Показано {this.state.cards.length} из {this.state.totalCount}</div>

                <table className="table table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" style={{ width: '500px' }}>Родительская зона</th>
                            <th scope="col">Название</th>
                            <th scope="col" style={{ width: '150px' }}>Параметры</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.cards.map(item => {
                            return (
                                <MkdItem key={item.ID} info={item} onClick={this.showModal} />
                            )
                        })}
                    </tbody>
                </table>

                {this.state.cards.length < this.state.totalCount ? this.getMoreButton() : null}

                <div style={{ height: '60px' }}></div>

                <AddBtn onClick={this.newClick()} />

                <Modal show={this.state.modalShow} onHide={this.hideEditModal} size="max">
                    <MkdForm
                        id={this.state.modalItemId}
                        hideCallback={this.hideEditModal}
                        parentReloadCallback={this.reloadCallback}
                        server={this.props.server}
                        mainUrl={this.mainUrl} />
                </Modal>

                <Modal show={this.state.showModalNew} onHide={this.hideModal()} size="xl" fullscreen="lg-down">
                    <MkdNewForm
                        server={this.props.server}
                        mainUrl={this.mainUrl}
                        //id={this.state.thisZone}
                        hideCallback={this.hideModal()}
                        parentReloadCallback={id => {
                            if (id) {
                                this.load()
                                this.showModal(id)
                            }
                        }} />
                </Modal>

                {(this.state.errorconnect) ? <Error /> : null}
            </div>
        )
    }
}