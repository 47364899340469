import Base from '../Base'

import React from 'react'
import SensorForm from "./GuardSensorForm"
import SensorItem from "./GuardSensorItem"
import TableSearch from "../../../Components/General/TableSearch/TableSearch"
import { Modal } from 'react-bootstrap'
import Error from '../../../Components/General/Alerts/error'
import AddBtn from '../../../Components/General/AddBtn'

import SearchInputObj from '../../../Components/General/SearchInputs/searchInputObj'
import Zone from '../../../Components/Zone'

export default class Acceleration_sensors extends Base {

    static mainUrl = 'guard-sensor'
    mainUrl = 'guard-sensor'

    filter = <>
        <SearchInputObj
            size={12}
            label="Зона"
            source="zone"
            server={this.props.server}
            render={item => <Zone item={item} />}
            onSelectItem={item => this.setFilter('zone', item.ID)}
        />
        <SearchInputObj
            size={12}
            label="Модель"
            source="guard-sensor-model"
            server={this.props.server}
            onSelectItem={item => this.setFilter('model', item.ID)}
        />
    </>

    render() {
        return (
            <div className="inmodule">

                <TableSearch filter={this.filter} searchParams={this.searchParams} setParentCallback={this.setCallback} />

                <div className="centerCount">Показано {this.state.cards.length} из {this.state.totalCount}</div>

                <table className="table table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" style={{ width: '300px' }}>Идентификатор устройства</th>
                            <th scope="col" style={{ width: '300px' }}>Модель</th>
                            <th scope="col">Зоны</th>
                            <th scope="col" style={{ width: '150px' }}>Параметры</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.cards.map(item => {
                            return (
                                <SensorItem key={item.ID} info={item} onClick={this.showModal} />
                            )
                        })}
                    </tbody>
                </table>

                {this.state.cards.length < this.state.totalCount ? this.getMoreButton() : null}

                <div style={{ height: '60px' }}></div>

                <AddBtn onClick={this.newClick()} />

                <Modal show={this.state.modalShow} onHide={this.hideEditModal} size="xl" fullscreen="lg-down">
                    <SensorForm
                        id={this.state.modalItemId}
                        hideCallback={this.hideEditModal}
                        parentReloadCallback={this.reloadCallback}
                        server={this.props.server}
                        mainUrl={this.mainUrl} />
                </Modal>

                {(this.state.errorconnect) ? <Error /> : null}
            </div>
        )
    }
}