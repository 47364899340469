import BaseForm from '../BaseForm'

import React from 'react'
import { Button, Form, Modal, Row } from 'react-bootstrap'

import FormControlsRender from '../../../Utils/FormControlsRender'
import { Text } from '../../../Utils/FormControlsPresets'
import ConfirmModal from '../../../Components/General/ConfirmModal'

export default class WeatherForm extends BaseForm {
  constructor(props) {
    super(props)

    this.idField = 'CITY_ID'

    this.state = {
      requestProcess: false,
      card: {},
      formControls: {
        TITLE: Text('Наименование'),
        CITY_ID: Text('Идентификатор населенного пункта в OpenWeatherMap'),
        INTERVAL: Text('Интервал обновления (час)')
      },
      postData: {}
    }

    this.id = props.id //для обновления ID
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{(this.state.card.CITY_ID ? 'Редактирование' : 'Создание') + ' источника погоды'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              {FormControlsRender('TITLE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-12')}
            </Row>
            <Row>
              {FormControlsRender('CITY_ID', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-12')}
            </Row>
            <Row>
              {FormControlsRender('INTERVAL', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-12')}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {this.id ? <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button> : null}
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
          <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
          <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.cancelClick}>Отмена</Button>
        </Modal.Footer>

        <ConfirmModal {...this.state.confirmModal}/>
      </>
    )
  }
}