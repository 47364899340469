import React from 'react'
import './inpFileButton.css'

export default function InputFileButton(props) {
    const id = Math.random().toString(36).substr(2, 9)
    return (
        <span className="input-file-group">
            <input type="file" id={id} name={props.name ?? 'file'} className="input-file" onChange={props.onChange} />
            <label htmlFor={id} className="btn btn-secondary">{props.label ?? 'Обзор'}</label>
        </span>
    )
}