import React from 'react'
import { toast } from 'react-toastify'

export default class BaseItem extends React.Component {

    onClick = id => {
        this.props.onClick(id)
    }

    copyCB = (text, event) => {
        event.stopPropagation()
        navigator.clipboard.writeText(text)
        toast.success('Скопировано ' + text)
        return false
    }
}