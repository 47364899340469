import BaseForm from '../BaseForm'

import React from 'react'
import { Modal, Button, Row } from 'react-bootstrap'

import FormControlsRender from '../../../Utils/FormControlsRender'
import { Select, Text, TextArea } from '../../../Utils/FormControlsPresets'
import { RequestWithToken } from '../../../Components/General/Request'
import Guid from '../../../Components/Guid'
import InputFileButton from '../../../Components/General/Inputs/Abonent/inpFileButton'
import { showNotificationErrors } from '../../../Utils/Helpers'
import ConfirmModal, { hideConfirmModal } from '../../../Components/General/ConfirmModal'

import { toast } from 'react-toastify'

export default class ClientForm extends BaseForm {
    constructor(props) {
        super(props)

        this.state = {
            requestProcess: false,
            card: {},
            formControls: {
                TITLE: Text('ФИО / Наименование'),
                TELSOT: Text('Тел. сот.'),
                TELDOM: Text('Тел. дом.'),
                EMAIL: Text('e-mail'),
                COMMENT: TextArea('Служебный комментарий'),
                TYPE_CLIENTS: Select('Тип клиента', [
                    ['', 'нет'],
                    [0, 'Юридическое лицо'],
                    [1, 'Физическое лицо'],
                    [2, 'Индивидуальный предприниматель'],
                    [-1, 'Иное (-1)'],
                ]),
            },
            postData: {},
            image: null,
            showModalImage: false
        }

        this.id = props.id //для обновления ID
    }

    openImageClick = file => {
        if (this.state[file]) {
            this.setState({ image: this.state[file], showModalImage: true })
        } else {
            this.loadPersFile(file, () => this.setState({ image: this.state[file], showModalImage: true }))
        }
    }

    saveImageClick = file => {
        if (this.state[file]) {
            this.saveImage(file)
        } else {
            this.loadPersFile(file, () => this.saveImage(file))
        }
    }

    saveImage = file => {
        const tempLink = document.createElement('a')
        tempLink.href = this.state[file].url
        tempLink.setAttribute('download', `${this.state.card?.IMAGE}`)
        tempLink.click()
    }

    loadPersFile = (file, callback) => {
        this.setState({ requestProcess: true })

        RequestWithToken(`retranslate/${this.props.server}/${this.props.mainUrl}/pers-file/${this.id}`)
            .getFile({ file })
            .then(data => {
                const type = file === 'pers_sogl' ? this.state.card.IMG_PERS_FOR_WEB : this.state.card.IMG_OPR_FOR_WEB
                const downloadedFile = new Blob([data], { type })//Build a URL from the file
                const url = URL.createObjectURL(downloadedFile)//Open the URL on new Window

                this.setState({ [file]: { url, type, file } }, callback)
            })
            .catch(e => {
                showNotificationErrors('Ошибка загрузки файла', e.response ?? null)
            })
            .finally(() => this.setState({ requestProcess: false })) 
    }

    delPersFile = file => {
        const title = file === 'pers_sogl' ? 'согласия на обработку персональных данных' : 'опросного листа'

        this.setState({
            confirmModal: {
                text: `Вы действительно хотите удалить файл ${title}?`,
                handleClose: hideConfirmModal.bind(this),
                handleAccept: hideConfirmModal.bind(this, () => {
                    this.setState({ requestProcess: true })

                    RequestWithToken(`retranslate/${this.props.server}/${this.props.mainUrl}/pers-file/${this.id}`)
                        .del({ file })
                        .then(() => toast.warning('Удалено'))
                        .catch(e => showNotificationErrors('Ошибка удаления файла', e.response ?? null))
                        .finally(() => {
                            this.load()
                            this.setState({ requestProcess: false })
                        })                        
                })
            }
        })
    }

    uploadFile = event => {
        const file = event.target.files[0]

        if (file) {
            this.setState({ requestProcess: true })
            
            const path_splitted = file.name.split('.')
            const extension = path_splitted.pop()

            let datein = new FormData()
            datein.append('files[]', file, `${event.target.name}.${extension}`)

            RequestWithToken(`retranslate/${this.props.server}/${this.props.mainUrl}/pers-file/${this.id}`)
                .setHeaders({ "Content-Type": "multipart/form-data" })
                .post(datein)
                .then(() => {
                    this.setState({ [event.target.name]: null })
                    toast.success('Файл успешно загружен')
                })
                .catch(e => showNotificationErrors('Ошибка загрузки файла', e.response ?? null))
                .finally(() => {
                    event.target.value = null
                    this.load()
                    this.setState({ requestProcess: false })
                })
        }
    }

    render() {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{(this.state.card.ID ? 'Редактирование' : 'Создание') + ' плательщика'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Guid key={this.id} value={this.id} />
                    </Row>
                    <Row>
                        {FormControlsRender('TITLE', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-8')}
                        {FormControlsRender('TYPE_CLIENTS', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
                    </Row>
                    <Row>
                        {FormControlsRender('TELSOT', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
                        {FormControlsRender('TELDOM', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
                        {FormControlsRender('EMAIL', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-4')}
                    </Row>
                    <Row>
                        {FormControlsRender('COMMENT', this.state.formControls, this.onChangeHandler, this.onSaveHandler(), 'col-md-12')}
                    </Row>
                    <Row className={this.id ? null : 'disabled'}>
                        <div className='col-md-6'>
                            <label className="form-label">Согласие на обработку персональных данных</label>
                            <div className='pers_sogl'>
                                {this.state.card?.IMG_PERS_FOR_WEB ?
                                    <>
                                        <Button variant="secondary" onClick={this.openImageClick.bind(this, 'pers_sogl')} title="Открыть">Открыть</Button>
                                        <Button variant="secondary" onClick={this.saveImageClick.bind(this, 'pers_sogl')} title="Скачать">Скачать</Button>
                                        <Button variant="secondary" onClick={() => this.delPersFile('pers_sogl')}>Удалить</Button>
                                    </>
                                    : <InputFileButton name="pers_sogl" onChange={this.uploadFile.bind(this)} label="Выбрать файл" />}

                            </div>
                        </div>
                        <div className='col-md-6'>
                            <label className="form-label">Опросный лист</label>
                            <div className='pers_sogl'>
                                {this.state.card?.IMG_OPR_FOR_WEB ?
                                    <>
                                        <Button variant="secondary" onClick={this.openImageClick.bind(this, 'opros_list')} title="Открыть">Открыть</Button>
                                        <Button variant="secondary" onClick={this.saveImageClick.bind(this, 'opros_list')} title="Скачать">Скачать</Button>
                                        <Button variant="secondary" onClick={() => this.delPersFile('opros_list')}>Удалить</Button>
                                    </>
                                    : <InputFileButton name="opros_list" onChange={this.uploadFile.bind(this)} label="Выбрать файл" />}
                            </div>
                        </div>
                    </Row>

                    <div style={{ height: '15px' }}></div>

                </Modal.Body>
                <Modal.Footer>
                    {this.id ? <Button disabled={this.state.requestProcess} variant="danger" onClick={this.delClick}>Удалить</Button> : null}
                    <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveClick}>Сохранить и продолжить</Button>
                    <Button disabled={this.disabledSaveButtons()} variant="primary" onClick={this.saveCloseClick}>Сохранить и закрыть</Button>
                    <Button disabled={this.state.requestProcess} variant="secondary" onClick={this.cancelClick}>Отмена</Button>
                </Modal.Footer>

                <Modal show={this.state.showModalImage} onHide={() => this.setState({ showModalImage: false })} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.image?.file === 'pers_sogl' ? 'Согласие на обработку персональных данных' : 'Опросный лист'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <object type={this.state.image?.type} data={this.state.image?.url} style={{ height: '90vh', width: '100%', objectFit: 'scale-down' }}>
                            Изображение
                        </object>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showModalImage: false })}>Отмена</Button>
                    </Modal.Footer>
                </Modal>

                <ConfirmModal {...this.state.confirmModal} />
            </>
        )
    }
}