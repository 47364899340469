import React from 'react'
import { RequestWithToken } from '../../../Components/General/Request'
import { toast } from 'react-toastify'
import MkdTable from '../../../Components/Mkd/mkdTable'
import { Modal, Button, Row } from 'react-bootstrap'
import ConfirmModal, { hideConfirmModal } from '../../../Components/General/ConfirmModal'

export default class MkdForm extends React.Component {
  state = {
    card: {},
    counters: {},
  }

  load2 = () => {
    this.load()
  }

  async load(id = this.props.id) {
    if (typeof id === 'string' || id instanceof String) {
      RequestWithToken(`retranslate/${this.props.server}/mkd/${id}`)
        .get()
        .then(data => {
          this.setState({
            card: data.items,
            counters: data.items.counters,
          })
        })
    }
  }


  async del() {
    RequestWithToken(`retranslate/${this.props.server}/mkd/${this.state.card.ZONE.ID}`)
      .delete()
      .then(() => {
        toast.warning('Удалено')
        this.props.hideCallback()
        this.props.parentReloadCallback()
      })
      .catch(() => toast.error('Ошибка удаления'))
  }


  async clear() {
    RequestWithToken(`retranslate/${this.props.server}/mkd/clear/${this.state.card.ZONE.ID}`)
      .del()
      .then(() => {
        toast.warning('Схема МКД очищена')
        this.props.hideCallback()
        this.props.parentReloadCallback()
      })
      .catch(() => toast.error('Ошибка очистки схемы МКД'))
  }

  delClick = event => {
    event.preventDefault()
    this.setState({
      confirmModal: {
        text: 'Вы действительно хотите удалить схему МКД?',
        handleClose: hideConfirmModal.bind(this),
        handleAccept: hideConfirmModal.bind(this, this.del.bind(this)),
      }
    })
  }

  clearClick = event => {
    event.preventDefault()
    this.setState({
      confirmModal: {
        text: 'Вы действительно хотите очистить схему МКД?',
        handleClose: hideConfirmModal.bind(this),
        handleAccept: hideConfirmModal.bind(this, this.clear.bind(this)),
      }
    })
  }

  input = event => {
    let card = { ...this.state.card }
    card[event.target.name] = event.target.value
    if (event.target.value === '') card[event.target.name] = null
    this.setState({
      ...this.state,
      card: card
    })
  }

  componentDidMount() {
    if (this.props.id) this.load()
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Редактирование схемы МКД</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {this.state.card.ZONE ?
            <>
              <Row>
                <div className="input-group mb-3 col-md-12">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">{this.state.card?.ZONE?.PARENT_TITLE ?? ''}</span>
                  </div>
                  <input type="text" className="form-control" defaultValue={this.state.card?.ZONE?.TITLE ?? ''} disabled />
                </div>
              </Row>
              <MkdTable data={this.state.card} server={this.props.server} zone={this.state.card.ZONE} load={this.load2} />
            </>
            :
            <p>Загрузка...</p>
          }

        </Modal.Body>
        <Modal.Footer>
          {this.state.card.ZONE ? <Button variant="danger" onClick={this.delClick}>Удалить</Button> : null}
          <Button variant="info" onClick={this.load2}>Обновить</Button>
          <Button variant="warning" onClick={this.clearClick}>Очистить схему</Button>
          <Button variant="secondary" onClick={this.props.hideCallback}>Отмена</Button>
        </Modal.Footer>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    )
  }
}