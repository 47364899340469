import React from 'react'
import BaseItem from '../BaseItem'

export default class MkdItem extends BaseItem {

    render() {

        return (
            <tr onClick={this.onClick.bind(this, this.props.info.ID)} style={{ cursor: 'pointer' }}>
                <td>
                    <small style={{ whiteSpace: 'nowrap', overflow: 'hidden', width: '50px' }}>
                        {this.props.info.PARENT_TITLE}
                    </small>
                </td>

                <th title={this.props.info.ID}>
                    {this.props.info.TITLE}
                </th>

                <td>
                    <i className="fas fa-fw fa-clipboard" title="Скопировать GUID"
                        onClick={this.copyCB.bind(this, this.props.info.ID)}> </i>
                    {this.props.info.COMMENT ? <i className="fas fa-fw fa-comment-dots" title={this.props.info.COMMENT}> </i> : <i className="fas fa-fw"> </i>}
                </td>
            </tr>

        )
    }
}