import React from 'react'
import {Row, Badge} from 'react-bootstrap'
import Cards from './Cards'
import Error from '../../Components/General/Alerts/error'
import { RequestWithToken } from '../../Components/General/Request'


export default class Dashboard extends React.Component {
  state = {
    errorconnect: false,
    cards: [],
  }


  async refresh() {
    const servers = {}   /// показать все серверы

    this.setState({errorconnect: false})

    RequestWithToken('statistic')
      .post(servers)
      .then(data => {
        this.setState({cards: data?.items ?? {}})
      })
      .catch(() => {
        this.setState({errorconnect: true})
        this.setState({cards: []})
      })

  }

  async componentDidMount() {
    this.refresh()
  }

  render() {
    return (
      <>
        <h1 className={'d-none d-sm-block'}>Ситуационный центр</h1>

        {(this.state.errorconnect) ? <Error onClick={() => this.refresh()} /> : null}

        <Row>
          {this.state.cards['col_servers'] ?
            <Cards hint="Количество выбранных серверов" value={this.state.cards['col_servers']} icon="fas fa-server"
                   text={'Обновлено: ' + new Date(this.state.cards['uod_time_server_min']).toLocaleString('ru-RU')}/>
            : null}

          {this.state.cards['bs'] ?
            <Cards hint="Общее количество базовых станций" value={this.state.cards['bs']} icon="fas fa-broadcast-tower"
                   text=""/>
            : null}

          {this.state.cards['lic_schet'] ?
            <Cards hint="Общее количество лицевых счетов" value={this.state.cards['lic_schet']} icon="fas fa-file-invoice"
                   text=""/>
            : null}

          {this.state.cards['cnt'] ?
            <Cards hint="Общее количество счётчиков" value={this.state.cards['cnt']} icon="fas fa-tachometer-alt"
                   text={<>
                     <Badge pill bg="success" title="Счётчики в сети">{this.state.cards['cnt_onl']}</Badge>&nbsp;
                     <Badge pill bg="danger" title="Счётчики не в сети">{this.state.cards['cnt_ofl']}</Badge>
                   </>}/>
            : null}

          {this.state.cards['cnt_gaz'] ?
            <Cards hint="Общее количество газовых счётчиков" value={this.state.cards['cnt_gaz']} icon="fas fa-fw res45 res-gaz"
                   text={<>
                     <div><Badge pill bg="success" title="Количество газовых счётчиков в сети за последний день">{this.state.cards['cnt_gaz'] - this.state.cards['cnt_gaz_ofl_day']}</Badge></div>
                     <Badge pill bg="danger" title="Количество газовых счётчиков не в сети за последний день">Д: {this.state.cards['cnt_gaz_ofl_day']}</Badge>&nbsp;
                     <Badge pill bg="danger" title="Количество газовых счётчиков не в сети за последнюю неделю">Н: {this.state.cards['cnt_gaz_ofl_week']}</Badge>&nbsp;
                     <Badge pill bg="danger" title="Количество газовых счётчиков не в сети за последний месяц">М: {this.state.cards['cnt_gaz_ofl_month']}</Badge>&nbsp;
                     <Badge pill bg="warning" title="Количество газовых счётчиков без указания координат">{this.state.cards['cnt_non_coord_gaz']}</Badge>
                   </>}/>
            : null}

          {this.state.cards['cnt_electro'] ?
            <Cards hint="Общее количество электро счётчиков" value={this.state.cards['cnt_electro']} icon="fas fa-fw res45 res-electro"
                   text={<>
                     <div><Badge pill bg="success" title="Количество электро счётчиков в сети за последний день">{this.state.cards['cnt_electro'] - this.state.cards['cnt_electro_ofl_day']}</Badge></div>
                     <Badge pill bg="danger" title="Количество электро счётчиков не в сети за последний день">Д: {this.state.cards['cnt_electro_ofl_day']}</Badge>&nbsp;
                     <Badge pill bg="danger" title="Количество электро счётчиков не в сети за последнюю неделю">Н: {this.state.cards['cnt_electro_ofl_week']}</Badge>&nbsp;
                     <Badge pill bg="danger" title="Количество электро счётчиков не в сети за последний месяц">М: {this.state.cards['cnt_electro_ofl_month']}</Badge>&nbsp;
                     <Badge pill bg="warning" title="Количество электро счётчиков без указания координат">{this.state.cards['cnt_non_coord_electro']}</Badge>
                   </>}/>
            : null}

          {this.state.cards['cnt_w_cold'] ?
            <Cards hint="Общее количество счётчиков холодной воды" value={this.state.cards['cnt_w_cold']} icon="fas fa-fw res45 res-w-cold"
                   text={<>
                     <div><Badge pill bg="success" title="Количество счётчиков холодной воды в сети за последний день">{this.state.cards['cnt_w_cold'] - this.state.cards['cnt_w_cold_ofl_day']}</Badge></div>
                     <Badge pill bg="danger" title="Количество счётчиков холодной воды не в сети за последний день">Д: {this.state.cards['cnt_w_cold_ofl_day']}</Badge>&nbsp;
                     <Badge pill bg="danger" title="Количество счётчиков холодной воды не в сети за последнюю неделю">Н: {this.state.cards['cnt_w_cold_ofl_week']}</Badge>&nbsp;
                     <Badge pill bg="danger" title="Количество счётчиков холодной воды не в сети за последний месяц">М: {this.state.cards['cnt_w_cold_ofl_month']}</Badge>&nbsp;
                     <Badge pill bg="warning" title="Количество счётчиков холодной воды не в сети без указания координат">{this.state.cards['cnt_non_coord_w_cold']}</Badge>
                   </>}/>
            : null}

          {this.state.cards['cnt_w_hot'] ?
            <Cards hint="Общее количество счётчиков горячей воды" value={this.state.cards['cnt_w_hot']} icon="fas fa-fw res45 res-w-hot"
                   text={<>
                     <div><Badge pill bg="success" title="Количество счётчиков горячей воды в сети за последний день">{this.state.cards['cnt_w_hot'] - this.state.cards['cnt_w_hot_ofl_day']}</Badge></div>
                     <Badge pill bg="danger" title="Количество счётчиков горячей воды не в сети за последний день">Д: {this.state.cards['cnt_w_hot_ofl_day']}</Badge>&nbsp;
                     <Badge pill bg="danger" title="Количество счётчиков горячей воды не в сети за последнюю неделю">Н: {this.state.cards['cnt_w_hot_ofl_week']}</Badge>&nbsp;
                     <Badge pill bg="danger" title="Количество счётчиков горячей воды не в сети за последний месяц">М: {this.state.cards['cnt_w_hot_ofl_month']}</Badge>&nbsp;
                     <Badge pill bg="warning" title="Количество счётчиков горячей воды не в сети без указания координат">{this.state.cards['cnt_non_coord_w_hot']}</Badge>
                   </>}/>
            : null}

          {this.state.cards['cnt_color'] ?
            <Cards hint="Общее количество счётчиков тепла" value={this.state.cards['cnt_color']} icon="fas fa-fw res45 res-colorimetr"
                   text={<>
                     <div><Badge pill bg="success" title="Количество счётчиков тепла в сети за последний день">{this.state.cards['cnt_color'] - this.state.cards['cnt_color_ofl_day']}</Badge></div>
                     <Badge pill bg="danger" title="Количество счётчиков тепла не в сети за последний день">Д: {this.state.cards['cnt_color_ofl_day']}</Badge>&nbsp;
                     <Badge pill bg="danger" title="Количество счётчиков тепла не в сети за последнюю неделю">Н: {this.state.cards['cnt_color_ofl_week']}</Badge>&nbsp;
                     <Badge pill bg="danger" title="Количество счётчиков тепла не в сети за последний месяц">М: {this.state.cards['cnt_color_ofl_month']}</Badge>&nbsp;
                     <Badge pill bg="warning" title="Количество счётчиков тепла не в сети без указания координат">{this.state.cards['cnt_non_coord_color']}</Badge>
                   </>}/>
            : null}

          {this.state.cards['cnt_undef'] ?
            <Cards hint="Общее количество счётчиков без типа" value={this.state.cards['cnt_undef']} icon="fas fa-question"
                   text={<>
                     <div><Badge pill bg="success" title="Количество счётчиков без типа в сети за последний день">{this.state.cards['cnt_undef'] - this.state.cards['cnt_undef_ofl_day']}</Badge></div>
                     <Badge pill bg="danger" title="Количество счётчиков без типа не в сети за последний день">Д: {this.state.cards['cnt_undef_ofl_day']}</Badge>&nbsp;
                     <Badge pill bg="danger" title="Количество счётчиков без типа не в сети за последнюю неделю">Н: {this.state.cards['cnt_undef_ofl_week']}</Badge>&nbsp;
                     <Badge pill bg="danger" title="Количество счётчиков без типа не в сети за последний месяц">М: {this.state.cards['cnt_undef_ofl_month']}</Badge>&nbsp;
                     <Badge pill bg="warning" title="Количество счётчиков без типа не в сети без указания координат">{this.state.cards['cnt_non_coord_undef']}</Badge>
                   </>}/>
            : null}

        </Row>


      </>
    )
  }
}